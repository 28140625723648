export const getBrowser = () => {
  const userAgent = window.navigator.userAgent;

  if (userAgent.indexOf('Chrome') > -1) {
    return 'Google Chrome';
  } else if (userAgent.indexOf('Safari') > -1) {
    return 'Apple Safari';
  } else if (userAgent.indexOf('Firefox') > -1) {
    return 'Mozilla Firefox';
  } else if (
    userAgent.indexOf('MSIE') > -1 ||
    userAgent.indexOf('Trident/') > -1
  ) {
    return 'Microsoft Internet Explorer';
  } else {
    return `Other browser; userAgent: ${userAgent}`;
  }
};

export const getOperatingSystem = () => {
  const userAgent = window.navigator.userAgent;

  if (userAgent.indexOf('Windows NT 10.0') !== -1) return 'Windows 10';
  if (userAgent.indexOf('Windows NT 6.2') !== -1) return 'Windows 8';
  if (userAgent.indexOf('Windows NT 6.1') !== -1) return 'Windows 7';
  if (userAgent.indexOf('Windows NT 6.0') !== -1) return 'Windows Vista';
  if (userAgent.indexOf('Windows NT 5.1') !== -1) return 'Windows XP';
  if (userAgent.indexOf('Windows NT 5.0') !== -1) return 'Windows 2000';
  if (userAgent.indexOf('Mac') !== -1) return 'Mac/iOS';
  if (userAgent.indexOf('X11') !== -1) return 'UNIX';
  if (userAgent.indexOf('Linux') !== -1) return 'Linux';
  return `Other operating system; userAgent: ${userAgent}`;
};

export const isSafariBrowser = () => {
  // return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  return (
    navigator.userAgent.indexOf('Safari') > -1 &&
    navigator.userAgent.indexOf('Chrome') <= -1
  );
};

export const isTouchScreen = window.matchMedia('(pointer: coarse)').matches;