import React, { FC, useEffect } from 'react';
import Logo from '../../shared/Logo/Logo';
import MenuBar from '../../shared/MenuBar/MenuBar';
import Navigation from '../../shared/Navigation/Navigation';
import { useSignOut } from 'react-auth-kit';
import { useNavigate, useLocation } from 'react-router-dom';
import { isTouchScreen } from '../../utils/userDeviceInfo';

export interface MainNav2LoggedProps {}

function LogOutCheck() {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (window.location.hash === '#logout') {
      // Handle logout logic here
      console.log('Logging out...');
      signOut();
      navigate('/');
    }
  }, [location]);

  return null;
}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  return (
    <div className={`nc-MainNav2Logged relative z-10 ${'onTop '}`}>
      <div className='container py-5 relative flex justify-between items-start md:items-center space-x-4 xl:space-x-8'>
        <div className='flex flex-col md:flex-row justify-start flex-grow items-start md:items-center space-x-3'>
          <LogOutCheck />
          <Logo />
          <Navigation />
        </div>
        <div className='flex-shrink-0 flex mt-2 md:mt-0 items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1'>
          <div className='items-center xl:flex space-x-2'>
            {/* <div className="hidden sm:block h-6 border-l border-neutral-300 dark:border-neutral-6000"></div>
                <div className="flex">
                  <SwitchDarkMode />
                  <NotifyDropdown />
                </div>
                <div></div> */}
          </div>

          <div
            className={`flex items-center space-x-3${
              isTouchScreen ? '' : ' lg:hidden'
            }`}
          >
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav2Logged;
