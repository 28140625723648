import React from 'react';
import SectionSliderCategories from '../../components/SectionSliderCategories/SectionSliderCategories';
import SectionHowItWork from '../../components/SectionHowItWork/SectionHowItWork';
import BackgroundSection from '../../components/BackgroundSection/BackgroundSection';
import BgGlassmorphism from '../../components/BgGlassmorphism/BgGlassmorphism';
import SectionBecomeAnAuthor from '../../components/SectionBecomeAnAuthor/SectionBecomeAnAuthor';
import SectionVideos from './SectionVideos';
import { Helmet } from 'react-helmet';
import SectionHero from '../../components/SectionHero/SectionHero';
import SectionLargeSlider from './SectionLargeSlider';
import SectionSliderCollections from '../../components/SectionSliderCollections';
import SectionGridFeatureNFT from './SectionGridFeatureNFT';
import SectionSubscribe2 from '../../components/SectionSubscribe2/SectionSubscribe2';
import { useNavigate } from 'react-router-dom';
import PageSearch from '../../containers/PageSearch';
import { FaChevronCircleRight } from 'react-icons/fa';

function PageSEO() {
  const navigate = useNavigate();

  return (
    <div className='nc-PageHome relative overflow-hidden'>
      <Helmet>
        <title>Skillfusion || The AI Tools Marketplace</title>
        <link
          rel='canonical'
          href='https://skillfusion.ai'
        />
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className='container relative space-y-20 mt-12 mb-20 sm:space-y-24 lg:space-y-32'>
        {/* SECTION HERO */}

        <div
          className={`nc-SectionHero relative pb-10`}
          data-nc-id='SectionHero'
        >
          <div className='flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 lg:items-start relative'>
            <div className='w-screen max-w-full xl:max-w-2/3 space-y-5 lg:space-y-7'>
              <h2 className='text-xl !leading-2 font-sans'>
                <span>
                  <h1 className='text-3xl'>
                    Skillfusion - Speed up Your Success With The Power of AI
                  </h1>
                  <br />

                  <p>- Over 90 tools including 30 Marketing tools (seo, content generation, social media marketing, image generation)</p>
                  <p>- Free tool maker where you can make your own AI Tools</p>
                  <br />

                  <p>
                    Tools that can:<br/>
                    ✔️ Write 3,000 word articles from a single keyword suggestions <br/>
                    ✔️ rewrite competitor content in bulk <br/>
                    ✔️ Summarise, translate, rewrite documents of any length<br/>
                    ✔️ Write 5,000 word guides as pdf lead magnets<br/>
                    ✔️ And much more...
                  </p>
                  <br />

                  <h2>
                    <FaChevronCircleRight
                      className='text-indigo-600'
                      style={{ display: 'inline' }}
                    />{' '}
                    <a
                      onClick={() => navigate('/signup')}
                      className='text-indigo-600 underline underline-offset-4 cursor-pointer'
                    >
                      Register Now
                    </a>
                    <br />
                    <FaChevronCircleRight
                      className='text-indigo-600'
                      style={{ display: 'inline' }}
                    />{' '}
                    <a
                      onClick={() => navigate('/page-search?q=Marketing')}
                      className='text-indigo-600 underline underline-offset-4 cursor-pointer'
                    >
                      Browse Tools
                    </a>
                    <br />
                    <FaChevronCircleRight
                      className='text-indigo-600'
                      style={{ display: 'inline' }}
                    />{' '}
                    <a
                      onClick={() => navigate('/fuser/0')}
                      className='text-indigo-600 underline underline-offset-4 cursor-pointer'
                    >
                      Make & Publish your own tools
                    </a>
                  </h2>
                  <br/><br/><br/>
                  <p className='text-3xl py-4'>
                    Do 10x more than you can with ChatGPT alone
                  </p>

                  <p>
                    - Tools created and vetted by real humans <br />
                    - multiple prompts combined <br />
                    - web access <br />
                    - image generation <br />
                    - embedded knowledge <br />
                    - Zapier connection <br />
                    - Google search <br />
                    - Longer output
                  </p>
                </span>
              </h2>
              <span className='block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 max-w-lg'>
                {/*subHeading*/}
              </span>
            </div>
            <div className='flex-grow'>
              <PageSearch mode='preview'/>
            </div>
          </div>
        </div>

        {/* SECTION 2 */}
        {/*<SectionHowItWork />*/}
      </div>

      {/* SECTION LAERGE SLIDER */}
    </div>
  );
}

export default PageSEO;
