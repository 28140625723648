import React, { useRef, useState } from "react"

const EditableField = ({ initialValue, inputType, onSave }: {
  initialValue: string,
  inputType?: string,
  onSave: any
}) => {
  const [isBeingEdited, setIsBeingEdited] = useState(false);

  const lastSavedValueRef = useRef(initialValue);
  const [value, setValue] = useState<string>(initialValue);
  
  const inputProps = {
    className: 'p-1 border border-black shrink-0 resize',
    value,
    onChange: (({ target: { value } }: any) => setValue(value))
  };

  const trimmedValue = value.length <= 30 ? value : `${value.slice(0, 50)}...`;

  return !isBeingEdited ? (
    <>
      <p>{trimmedValue}</p>
      <button
        className='border border-black px-2 mr-2'
        onClick={() => setIsBeingEdited(true)}
      >
        ✎
      </button>
    </>
  ) : (
    <>
      {inputType === 'textarea'
        ? <textarea {...inputProps} />
        : <input {...inputProps} />}
      <button
        className='border border-black px-2 mr-2'
          onClick={async () => {
            try {
              console.log({value})
              await onSave(value);
              lastSavedValueRef.current = value;
            } catch (error) {
              alert(error.response.data);
            } finally {
              setIsBeingEdited(false);
            }
          }}
      >
        ✓
      </button>
      <button
        className='border border-black px-2 mr-2'
          onClick={() => {
            setIsBeingEdited(false);
            setValue(lastSavedValueRef.current)
          }}
      >
        X
      </button>
    </>
  )
};

export default EditableField;