import React from 'react';
import { MdClose } from 'react-icons/md';

function ModalContainer({onClose, children }: any) {
  return (
    <div className="share-modal fixed inset-0 flex justify-center">
      <div className="absolute inset-0 bg-black opacity-50 z-10"></div>
      <div className="relative z-20">
        <div className="m-auto flex items-center justify-center p-4">
          <div className="bg-white p-4 rounded shadow-md m-auto max-w-[90vw] z-20">
            <div className="flex flex-col gap-4">
              <div>
                <button
                  className="sticky left-0 top-0"
                  onClick={onClose}
                >
                  <MdClose />
                </button>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalContainer;
