import React, { FC, useState, useEffect, useContext } from 'react';

import FuserLoader from '../../containers/FuserPage/FuserLoader';

import BlockProps from '../../models/BlockProps';
import Block from '../../models/Block';
import FuserContext from '../../context/FuserContext';
import useBlockRunner from '../../hooks/useBlockRunner';
import AutocompleteTextarea from '../../containers/FuserPage/AutocompleteTextarea';
import { testButtonStyles } from '../../constants/styles';
import {
  concatUniqueFromInside,
  deepRemoveDuplicates,
  ensureArray,
  ensureNotArray,
  flattenFromInside,
  getArrayDimensions,
  is1dArray,
  joinEverything,
  joinFromInside,
} from '../../utils/array';
import { blockReferenceRegex } from '../../constants/blocks';
import { replacePlaceholders, tidyUpResponse } from '../../utils/fuser';
import { isArray } from 'chart.js/dist/helpers/helpers.core';

const ReducingBlock: FC<BlockProps> = ({
  isLoading,
  setIsLoading,
  index,
  block,
  handleChange,
  updateBlocks,
  resultHtml,
}) => {
  const { blockStyles, runnerMode, blocks, setBlocks, setActivityLog } =
    useContext(FuserContext);

  useEffect(() => setStatusMessage(''), [runnerMode]);

  useBlockRunner(onReduceClick, index);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [statusMessage, setStatusMessage] = useState('');

  const [responseDescription, setResponseDescription] = useState('');

  const {
    response,
    inputToProcess,
    selectValue,
    checkedRadioValue,
    customDelimiter,
  } = block.data;

  const radioGroupName = `radio-group-${index}`;

  return (
    <FuserLoader
      name='Reducing Block'
      loading={isLoading}
    >
      {runnerMode ? (
        <>
          <div>Reducing block</div>
          <p>{statusMessage}</p>
        </>
      ) : (
        <div
          className={blockStyles}
          key={index}
        >
          <label
            className='text-xs'
            id='prompt-textarea'
          >
            Input to be reduced:
          </label>

          <AutocompleteTextarea
            block={block}
            index={index}
            onChange={handleChange}
            className='w-full prompt-textarea bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
            name='inputToProcess'
            value={inputToProcess || ''}
          />

          <label id='block-select'>type of join:</label>

          <select
            className='text-xs bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
            id='block-select'
            name='selectValue'
            value={selectValue}
            onChange={handleChange}
          >
            <option value=','>Commas</option>
            <option value=' '>Spaces</option>
            <option value='\n'>New Lines</option>
            <option value='\n-----\n'>Dashed line</option>
            <option value='\n=====\n'>Bold dashed line</option>
            <option value='numbered-headings'>Numbered headings</option>
            <option value='custom'>Custom</option>
          </select>

          {selectValue === 'custom' && (
            <div className='w-full flex items-center gap-2'>
              <label
                htmlFor='customSeparator'
                className='w-max'
              >
                Enter custom delimiter here:
              </label>
              <AutocompleteTextarea
                autosize={true}
                block={block}
                index={index}
                containerClassName='grow flex'
                className='w-32 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                name='customDelimiter'
                value={customDelimiter ?? ''}
                onChange={handleChange}
              />
            </div>
          )}
          <label className='flex gap-2 items-center'>
            <input
              type='checkbox'
              onChange={() => {
                block.data.removeInnerDuplicates =
                  !block.data.removeInnerDuplicates;
                updateBlocks();
              }}
              checked={block.data.removeInnerDuplicates}
            />
            Remove duplicates
          </label>

          <label className='flex gap-2 items-center'>
            <input
              type='checkbox'
              onChange={() => {
                block.data.reduceSideways = !block.data.reduceSideways;
                updateBlocks();
              }}
              checked={block.data.reduceSideways}
            />
            Reduce sideways
          </label>

          <label className='flex gap-2 items-center'>
            <input
              type='checkbox'
              onChange={() => {
                block.data.flatten = !block.data.flatten;
                updateBlocks();
              }}
              checked={block.data.flatten}
            />
            Flatten (instead of joining)
          </label>

          <fieldset>
            <legend>Reducing depth:</legend>

            <div className='grid grid-cols-[auto_1fr] gap-2 items-center'>
            <input
                type='radio'
                id='upto-one-level'
                name={radioGroupName}
                value='upto-one-level'
                checked={checkedRadioValue === 'upto-one-level'}
                onChange={handleChange}
              />
              <label htmlFor={radioGroupName}>get rows</label>

              <input
                type='radio'
                id='one-level'
                name={radioGroupName}
                value='one-level'
                checked={checkedRadioValue === 'one-level'}
                onChange={handleChange}
              />
              <label htmlFor={radioGroupName}>One level</label>

              <input
                type='radio'
                id='two-levels'
                name={radioGroupName}
                value='two-levels'
                checked={checkedRadioValue === 'two-levels'}
                onChange={handleChange}
              />
              <label htmlFor={radioGroupName}>Two levels</label>

              <input
                type='radio'
                id='three-levels'
                name={radioGroupName}
                value='three-levels'
                checked={checkedRadioValue === 'three-levels'}
                onChange={handleChange}
              />
              <label htmlFor={radioGroupName}>Three levels</label>

              <input
                type='radio'
                id='four-levels'
                name={radioGroupName}
                value='four-levels'
                checked={checkedRadioValue === 'four-levels'}
                onChange={handleChange}
              />
              <label htmlFor={radioGroupName}>Four levels</label>

              <input
                type='radio'
                id='all-levels'
                name={radioGroupName}
                value='all-levels'
                checked={checkedRadioValue === 'all-levels'}
                onChange={handleChange}
              />
              <label htmlFor={radioGroupName}>All levels</label>
            </div>
          </fieldset>

          <button
            onClick={onReduceClick}
            className={testButtonStyles}
          >
            TEST
          </button>
          <div
            className='overflow-x-auto'
            dangerouslySetInnerHTML={{ __html: resultHtml }}
          />
          <p>{responseDescription}</p>

          <p>{errorMessage}</p>

          <textarea
            value={response}
            readOnly
            className='resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner h-36 overflow-y-scroll w-full'
          />
        </div>
      )}
    </FuserLoader>
  );

  async function onReduceClick() {
    const {
      inputToProcess,
      checkedRadioValue: depth,
      selectValue,
      removeInnerDuplicates,
      reduceSideways,
      flatten
    } = block.data;

    setIsLoading(true);
    setStatusMessage('Combining content');
    
    try {
      let processedInput: string[] | string = inputToProcess
        ? replacePlaceholders(inputToProcess, blocks)
        : '';

      if (removeInnerDuplicates) {
        // console.log('Reducing input (with redundancies):', processedInput);
        processedInput = deepRemoveDuplicates(processedInput);
      }
      
      // console.log('Reducing input (deduplicated):', processedInput);

      let response: any;

      if (reduceSideways) {
        const inputArrays = ensureNotArray(inputToProcess)
          .match(blockReferenceRegex)
          ?.map((reference: string) => replacePlaceholders(reference, blocks));

        response = Array.from({ length: inputArrays[0].length }, () => []);

        for (const array of inputArrays) {
          for (let i = 0; i < array.length; i++) {
            response[i].push(...ensureArray(array[i]));
          }
        }
      } else {
        if (selectValue === 'numbered-headings') {
          response = ensureArray(processedInput)
            .map((line, index) => `${index + 1}. ${line}`)
            .join('\n');
        } else {
          let delimiter = selectValue.replace(/\\n/g, '\n');
          if (delimiter === 'custom') {
            delimiter = block.data.customDelimiter;
          }

          const reduce: any = (numberOfLevels: number) => {
            return (
              flatten ?
              flattenFromInside(
                numberOfLevels === 1 ? processedInput : reduce(numberOfLevels - 1)
              ) :
              joinFromInside(
                delimiter,
                numberOfLevels === 1 ? processedInput : reduce(numberOfLevels - 1)
              )
            );
          };

          // const getArrayDepth = (arr: unknown): number => {
          //   if (Array.isArray(arr)) {
          //     return 1 + Math.max(0, ...arr.map((item) => getArrayDepth(item)));
          //   } else {
          //     return 0;
          //   }
          // };
          
          // const reduce: any = (numberOfLevels : number, input = processedInput) => {
          //   // Base case: If numberOfLevels is 0, reduce until depth is 1
          //   if (numberOfLevels === 0) {
          //     // Reduce until input is 1D
          //     if (getArrayDepth(input) <= 1) {
          //       return input;
          //     } else {
          //       const nextInput = flatten
          //         ? flattenFromInside(input)
          //         : joinFromInside(delimiter, input);
          //       return reduce(0, nextInput);
          //     }
          //   } else if (numberOfLevels === 1) {
          //     return input;
          //   } else {
          //     const nextInput = flatten
          //       ? flattenFromInside(input)
          //       : joinFromInside(delimiter, input);
          //     return reduce(numberOfLevels - 1, nextInput);
          //   }
          // };

          if (depth === 'upto-one-level') {
            console.log(processedInput[0].length);
            while (Array.isArray(processedInput[0])  && processedInput[0].length>1 ) {
              console.log("runnning...");
              processedInput = joinFromInside(delimiter, processedInput);
            }
           //if (isArray(processedInput)) response = processedInput.flat(); 
           if (Array.isArray(processedInput)) response = processedInput.flat(); else response = processedInput;
           // ^ not working as its an array inside an array
           //response = processedInput;
          }
          else response = (
            depth === 'one-level'
              ? reduce(1)
              : depth === 'two-levels'
                ? reduce(2)
                : depth === 'three-levels'
                  ? reduce(3)
                  : depth === 'four-levels'
                    ? reduce(4)
                    : joinEverything(delimiter, processedInput)
          );
        }
      }
      // console.log('Reducing response:', response);
      const blockUpdater = (blocks: Block[]) => {
        const newBlocks = [...blocks];
        newBlocks[index] = {
          ...newBlocks[index],
          updatedBlock: runnerMode,
          data: {
            ...newBlocks[index].data,
            type: 'reducing',
            response,
          },
        };
        return newBlocks;
      };

      setResponseDescription(tidyUpResponse(response));

      setStatusMessage('Combining complete');
      setActivityLog((prevLog: string[]) => [
        ...prevLog,
        `Saved reducing block at index: ${index}`,
      ]);

      if (!runnerMode) setBlocks(blockUpdater);
      else return blockUpdater;
    }
    catch (error) {
      setStatusMessage('Error combining content');
      console.log('Error combining content:', error)
    }
    finally {
      setIsLoading(false);
    }
  }
};

export default ReducingBlock;
