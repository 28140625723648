import React, { FC, useContext } from 'react';
import MyToolTips from '../../components/MyTooltip';
import FuserContext from '../../context/FuserContext';

interface Props {
  labelText: string;
  name: string;
  min: string;
  max: string;
  value: string | number;
  step?: string;
  tooltipContent?: string;
  handleChange: any;
}

const GptParameterSection: FC<Props> = ({
  labelText,
  name,
  min,
  max,
  value = 0,
  step = 0.01,
  tooltipContent,
  handleChange,
}) => {
  return (
    <div className='flex flex-col gap-2'>
      <label className='text-xs flex gap-2 items-center'>
        {labelText}
        {tooltipContent && (
          <MyToolTips
            tipID={`${name}-gpt-parameter-definition`}
            content={tooltipContent}
            datatooltipplace='left'
          />
        )}
        <input
          className='w-24 h-6 rounded-xl bg-transparent'
          name={name}
          type='number'
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={handleChange}
        />
      </label>

      <input
        name={name}
        type='range'
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default GptParameterSection;
