import { excelColumnToNumber, numberToExcelColumn } from './math';

/* eslint-disable @typescript-eslint/no-explicit-any */
const { isArray } = Array;

export type multiDimensionalArray<T> = T[] | multiDimensionalArray<T>[];

const ensureArray = (x: any) => (isArray(x) ? x : [x]);

const ensureNotArray = (x: any) => (isArray(x) ? x[0] : x);

const batchArray = (batchSize: number, array: any[]) => {
  const batches: any[] = [];
  for (
    let arrayElementIndex = 0;
    arrayElementIndex < array.length;
    arrayElementIndex++
  ) {
    const arrayElement = array[arrayElementIndex];
    const batchIndex = Math.floor(arrayElementIndex / batchSize);
    const batchForElement = batches[batchIndex];

    if (batchForElement === undefined) batches[batchIndex] = [arrayElement];
    else batches[batchIndex] = [...batchForElement, arrayElement];
  }
  return batches;
};

const sum = (array: number[]) => {
  return array.reduce((x, y) => x + y, 0);
};

const average = (array: number[]) => {
  return sum(array) / array.length;
};

const isNonEmptyArray = (x: any) => Array.isArray(x) && x.length > 0;

const is1dArray = (x: any) => {
  return isArray(x) && x.every(element => !isArray(element));
};

const is2dArray = (x: any) => {
  return (
    isArray(x) &&
    x.length > 0 &&
    x.every(
      (row: any) =>
        isArray(row) &&
        row.every((element: any) => !isArray(element) || element.length === 1)
    )
  );
};

const map = function <T, U>(f: (...args: T[]) => U, ...arrays: T[][]): U[] {
  const resultLength = arrays[0].length;
  const result: U[] = [];

  for (let i = 0; i < resultLength; i++) {
    result.push(f(...arrays.map(array => array[i])));
  }

  return result;
};

const deleteAtIndex = function <T>(indexToDelete: number, array: T[]) {
  return array.filter(
    (element, elementIndex) => elementIndex !== indexToDelete
  );
};

const prettyPrintStringArray = function (array: string[]) {
  return `[${array.join(', ')}]`;
};

const prettyPrintMultidimensionalStringArray = function (
  array: any[] | string
): string {
  if (!isArray(array)) return array;
  else if (!isArray(array[0])) {
    return prettyPrintStringArray(array);
  } else {
    return `[${array.map(prettyPrintMultidimensionalStringArray).join(', ')}]`;
  }
};

const getArrayDimensions = function (arr: any): number {
  if (!isArray(arr)) {
    return 0;
  } else {
    if (arr.length === 1 && !isArray(arr[0])) return 0;
  }

  let dimensions = 1;
  for (let i = 0; i < arr.length; i++) {
    dimensions = Math.max(dimensions, getArrayDimensions(arr[i]) + 1);
  }

  return dimensions;
};

const concatEverything = function (
  delimiter: string,
  array: multiDimensionalArray<string> | string
): any {
  if (!isArray(array)) return array;
  else
    return array
      .map(element => concatEverything(delimiter, element))
      .join(delimiter);
};

const removeDuplicates = (array: any[]) => [...new Set(array)];

const deepRemoveDuplicates = (array: any) => {
  const uniqueElements = new Set();

  function recurse(element: any): any {
    if (!Array.isArray(element)) return element;

    const array = element
      .map(item => (Array.isArray(item) ? recurse(item) : item));
    
    for (let i = 0; i < array.length; i++) {
      if (uniqueElements.has(array[i])) array[i] = ''; 
      else uniqueElements.add(array[i]);
    } 

    return array;
  }

  return recurse(array);

}

const concatUniqueFromInside = function (delimiter: string, toJoin: any): any {
  if (typeof toJoin === 'string') {
    return toJoin;
  } else if (typeof toJoin[0] === 'string') {
    return removeDuplicates(toJoin).join(delimiter);
  } else if (
    isArray(toJoin) &&
    toJoin.every(
      (element: any) =>
        isArray(element) &&
        element.every((subElement: any) => !isArray(subElement))
    )
  ) {
    return removeDuplicates(toJoin.flat());
  } else {
    // console.log('big array:', toJoin);
    return toJoin
      .filter((subArray: any) => subArray.length > 0)
      .map((subArray: any) => concatUniqueFromInside(delimiter, subArray));
  }
};

const joinEverything = function (
  delimiter: string,
  array: multiDimensionalArray<string> | string
): any {
  if (!isArray(array)) return array;
  else return array
    .map(element => joinEverything(delimiter, element))
    .join(delimiter);
};

const getMaxDepth = function (array: any) {
  if (!Array.isArray(array)) return 0;

  let maxDepth = 0;

  for (const element of array) {
    maxDepth = Math.max(maxDepth, getMaxDepth(element));
  }

  return maxDepth + 1;
}

const flattenFromInside = function (toFlatten: any) {
  const maxDepth = getMaxDepth(toFlatten);

  return recurse(toFlatten);

  function recurse(element: any, depth = 0): any {
    if (!isArray(element) || is1dArray(element)) return element;
    if (depth === maxDepth - 2) {
      return element.flat().filter((x: any) => x !== '');
    }
    return element.map(subElement => recurse(subElement, depth + 1))
  }
}

const joinFromInside = function (delimiter: string, toJoin: any): any {
  if (typeof toJoin === 'string') return toJoin;

  if (typeof toJoin[0] === 'string') {
    return toJoin.filter((x: any) => x !== '').join(delimiter);
  }

  if (
    toJoin.every((element: any) => isArray(element) && element.length === 1)
  ) {
    return joinFromInside(delimiter, toJoin.flat());
  }

  return toJoin
    .filter((subArray: any) => subArray?.length > 0)
    .map((subArray: any) => joinFromInside(delimiter, subArray));
};

const updateAtIndex = function <T>(
  indexToUpdate: number,
  newValue: T,
  array: T[]
) {
  return [
    ...array.slice(0, indexToUpdate),
    newValue, //  { ...newValue, updatedBlock: true },
    ...array.slice(indexToUpdate + 1),
  ];
};

const updateAtIndexRun = function <T>(
  indexToUpdate: number,
  newValue: T,
  array: T[]
) {
  return [
    ...array.slice(0, indexToUpdate),
    { ...newValue, updatedBlock: true },
    ...array.slice(indexToUpdate + 1),
  ];
};

const flatten = function (arr: any[]): any[] {
  return arr.reduce((flat, toFlatten) => {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    );
  }, []);
};

const unflatten = function (arr: any[], pattern: any[]): any[] {
  let index = 0;
  function unflattenHelper(pattern: any[]): any[] {
    if (Array.isArray(pattern)) {
      return pattern.map(unflattenHelper);
    }
    return arr[index++];
  }
  return unflattenHelper(pattern);
};

const deepMap = function <T>(
  f: (t: T) => any,
  multiArray: multiDimensionalArray<T>
): multiDimensionalArray<any> {
  return multiArray.map(element =>
    Array.isArray(element) ? deepMap(f, element) : f(element)
  );
};

const filterInner = (predicate: any, array: any) => {
  return is1dArray(array)
    ? array.filter(predicate)
    : array.map((element: any) => filterInner(predicate, element));
};

const ensureFlattenedToString = function (arrayOrString: any) {
  return typeof arrayOrString === 'string'
    ? arrayOrString
    : arrayOrString.flat(Infinity).toString();
};

const transpose = function (array: any[]) {
  const rows = array.length;
  const cols = array[0]?.length;

  if (rows === 0) {
    return [];
  }

  // 1d array case
  if (cols === undefined) {
    return array.map(element => [element]);
  }

  const transposed = new Array(cols);
  for (let i = 0; i < cols; i++) {
    transposed[i] = new Array(rows);
  }

  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < cols; j++) {
      transposed[j][i] = array[i][j];
    }
  }

  return transposed;
};

const isDigits = (string: string) => /^\d+$/.test(string);
const isLetters = (string: string) => /^[a-zA-Z]+$/.test(string);
const matchCellReference = (string: string) =>
  string.match(/^([a-zA-Z]+)(\d+)$/);

function excelRangeToSubarray({ range, array, matchHeadings }: any) {
  if (range.includes(':')) {
    const [rangeStart, rangeEnd] = range.split(':');
    if (isDigits(rangeStart) && isDigits(rangeEnd)) {
      const [startRowIndex, endRowIndex] = [+rangeStart - 1, +rangeEnd - 1];
      if (array?.[startRowIndex] === undefined) {
        return undefined;
      } else {
        return array.slice(startRowIndex, endRowIndex + 1);
      }
    }

    if (isLetters(rangeStart) && isLetters(rangeEnd)) {
      const startColumnIndex = excelColumnToNumber(rangeStart) - 1;
      if (array[0]?.[startColumnIndex] === undefined) {
        return undefined;
      } else {
        const endColumnIndex = excelColumnToNumber(rangeEnd) - 1;
        if (!matchHeadings) {
          array = array.slice(1);
        }
        return array.map((row: any[]) =>
          row.slice(startColumnIndex, endColumnIndex + 1)
        );
      }
    }

    const [startCellMatch, endCellMatch] = [
      matchCellReference(rangeStart),
      matchCellReference(rangeEnd),
    ];

    if (startCellMatch && endCellMatch) {
      const [startColumnIndex, endColumnIndex] = [
        excelColumnToNumber(startCellMatch[1]) - 1,
        excelColumnToNumber(endCellMatch[1]) - 1,
      ];
      const [startRowIndex, endRowIndex] = [
        +startCellMatch[2] - 1,
        +endCellMatch[2] - 1,
      ];
      if (
        array[0]?.[startColumnIndex] === undefined ||
        array?.[startRowIndex] === undefined
      ) {
        return undefined;
      } else {
        return array
          .map((row: any[]) => row.slice(startColumnIndex, endColumnIndex + 1))
          .slice(startRowIndex, endRowIndex + 1);
      }
    }
  }
  else {
    if (isDigits(range)) {
      return array?.[+range - 1];
    }

    if (isLetters(range)) {
      const columnIndex = excelColumnToNumber(range) - 1;
      if (array[0]?.[columnIndex] === undefined) {
        return undefined;
      } else {
        if (!matchHeadings) {
          array = array.slice(1);
        }
        return array.map((row: any[]) => row?.[columnIndex]);
      }
    }

    const cellMatch = matchCellReference(range);

    if (cellMatch) {
      const columnIndex = excelColumnToNumber(cellMatch[1]) - 1;
      const rowIndex = +cellMatch[2] - 1;
      return array?.[rowIndex]?.[columnIndex];
    }
  }

  console.log(`Invalid range ${range} given to ${array}`);
  return undefined;
}

function setRange({
  startRowIndex = 0,
  endRowIndex,
  startColumnIndex = 0,
  endColumnIndex,
  array,
  newValues
}: {
  startRowIndex?: number,
  endRowIndex?: number,
  startColumnIndex?: number,
  endColumnIndex?: number,
  array: any[][],
  newValues: any,
}) {
  if (array[startRowIndex]?.[startColumnIndex] === undefined) return;

  const numberOfRowsInArray = array.length;
  const numberOfColumnsInArray = array[0]?.length ?? 0;

  const numberOfRowsInNewValues = newValues.length;
  const numberOfColumnsInNewValues = newValues[0]?.length ?? 0;

  const rangeEndRowIndex = Math.min(
    endRowIndex ?? Infinity,
    numberOfRowsInArray - 1,
    startRowIndex + numberOfRowsInNewValues - 1
  );

  const rangeEndColumnIndex = Math.min(
    endColumnIndex ?? Infinity,
    numberOfColumnsInArray - 1,
    startColumnIndex + numberOfColumnsInNewValues - 1
  );

  // console.log({ startRowIndex, rangeEndRowIndex, startColumnIndex, rangeEndColumnIndex });

  for (let rowIndex = startRowIndex; rowIndex <= rangeEndRowIndex; rowIndex++) {
    for (
      let columnIndex = startColumnIndex;
      columnIndex <= rangeEndColumnIndex;
      columnIndex++
    ) {
      array[rowIndex][columnIndex] = (
        newValues[rowIndex - startRowIndex][columnIndex - startColumnIndex]
      )
    }
  }
}

function updateArrayUsingExcelRange(
  { range, array, newValues, transposeNewValues, matchHeadings }: any
) {
  if (transposeNewValues) newValues = transpose(newValues);
  
  if (range.includes(':')) {
    const [rangeStart, rangeEnd] = range.split(':');

    if (isDigits(rangeStart) && isDigits(rangeEnd)) {
      const [startRowIndex, endRowIndex] = [+rangeStart - 1, +rangeEnd - 1];
      setRange({ startRowIndex, endRowIndex, array, newValues });
    }
    else if (isLetters(rangeStart) && isLetters(rangeEnd)) {
      const startColumnIndex = excelColumnToNumber(rangeStart) - 1;
      const endColumnIndex = excelColumnToNumber(rangeEnd) - 1;
      const startRowIndex = matchHeadings ? 1 : 0;

      setRange(
        { startColumnIndex, endColumnIndex, startRowIndex, array, newValues }
      );
    }
    else {
      const [startCellMatch, endCellMatch] = [
        matchCellReference(rangeStart),
        matchCellReference(rangeEnd),
      ];

      if (!startCellMatch || !endCellMatch) return;

      const [startColumnIndex, endColumnIndex] = [
        excelColumnToNumber(startCellMatch[1]) - 1,
        excelColumnToNumber(endCellMatch[1]) - 1,
      ];

      const [startRowIndex, endRowIndex] = [
        +startCellMatch[2] - 1,
        +endCellMatch[2] - 1,
      ];

      setRange({
        startColumnIndex,
        endColumnIndex,
        startRowIndex,
        endRowIndex,
        array,
        newValues
      });
    }
  }
  else {
    if (isDigits(range)) {
      const rowIndex = range - 1;
      setRange({
        startRowIndex: rowIndex,
        endRowIndex: rowIndex,
        array,
        newValues
      });
    }
    else if (isLetters(range)) {
      const columnIndex = excelColumnToNumber(range) - 1;
      const startRowIndex = matchHeadings ? 1 : 0;
      setRange({
        startColumnIndex: columnIndex,
        endColumnIndex: columnIndex,
        startRowIndex,
        array,
        newValues
      });
    }
    else {
      const cellMatch = matchCellReference(range);

      if (!cellMatch) return;
      
      const columnIndex = excelColumnToNumber(cellMatch[1]) - 1;
      const rowIndex = +cellMatch[2] - 1;
      setRange({
        startColumnIndex: columnIndex,
        endColumnIndex: columnIndex,
        startRowIndex: rowIndex,
        endRowIndex: rowIndex,
        array,
        newValues
      });
    }
  }
}

function replaceHeadingsWithColumnLetterInExcelRange({ headings, range }: {
  headings: string[], range:string
}) {
  // find the longest heading matching each part of the range to be consistent
  // for example, if there are headings called head and head2 then the range 
  // head2 matches the column head2 instead of the cell in row 2 of column head
  // the notation isn't perfect so if headings are badly named there will always
  // be some cells that are inaccessible using heading names; in this case
  // one example is the cell in row 2 of column head

  const headingsInDescendingLengthOrder = [...headings]
    .filter(x => x) // remove empty cells
    .sort(
      (heading1, heading2) => heading2.length - heading1.length
    );

  
  const firstMatchedHeading = headingsInDescendingLengthOrder.find(
    (heading) => range.includes(heading)
  );

  if (firstMatchedHeading !== undefined) {
    const firstMatchedHeadingIndex = headings.findIndex(
      (heading) => heading === firstMatchedHeading
    );

    range = range.replace(
      firstMatchedHeading,
      numberToExcelColumn(firstMatchedHeadingIndex + 1)
    );
  }

  const secondMatchedHeading = headingsInDescendingLengthOrder.find(
    (heading) => range.includes(heading)
  );
  
  if (secondMatchedHeading !== undefined) {
    const secondMatchedHeadingIndex = headings.findIndex(
      (heading) => heading === secondMatchedHeading
    );

    range = range.replace(
      secondMatchedHeading,
      numberToExcelColumn(secondMatchedHeadingIndex + 1)
    );
  }

  return range;
}

export {
  batchArray,
  sum,
  average,
  isNonEmptyArray,
  is1dArray,
  is2dArray,
  ensureArray,
  ensureNotArray,
  map,
  deleteAtIndex,
  prettyPrintMultidimensionalStringArray,
  getArrayDimensions,
  joinEverything,
  joinFromInside,
  // concatEverything,
  concatUniqueFromInside,
  updateAtIndex,
  updateAtIndexRun,
  flatten,
  unflatten,
  deepMap,
  ensureFlattenedToString,
  filterInner,
  transpose,
  excelRangeToSubarray,
  updateArrayUsingExcelRange,
  replaceHeadingsWithColumnLetterInExcelRange,
  removeDuplicates,
  deepRemoveDuplicates,
  flattenFromInside
};
