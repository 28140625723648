import React, { FC, useState, useEffect, useContext } from "react";
import FuserLoader from "../../containers/FuserPage/FuserLoader";
import BlockProps from "../../models/BlockProps";
import FuserContext from "../../context/FuserContext";
import Block from "../../models/Block";
import useBlockRunner from "../../hooks/useBlockRunner";
import HTMLPreviewContainer from "../../containers/FuserPage/HTMLPreviewContainer";
import AutocompleteTextarea from "../../containers/FuserPage/AutocompleteTextarea";
import { testButtonStyles } from "../../constants/styles";
import { replacePlaceholders } from "../../utils/fuser";

const HTMLPreviewBlock: FC<BlockProps> = ({
  isLoading,
  setIsLoading,
  index,
  block,
  handleChange,
  collapsed,
}) => {
  const { blockStyles, runnerMode, blocks, setBlocks, setActivityLog } =
    useContext(FuserContext);

  useBlockRunner(onTestClick, index);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const response = block?.data?.response;

  const processedHTML = response
    ? Array.isArray(response)
      ? response[0]
      : response
    : "";

  // disable iframe to begin with to prevent any malicious changes from automatically running
  const [iframeEnabled, setIframeEnabled] = useState(false);

  // triggers a reload on change
  const [iframeReloadTrigger, setIframeReloadTrigger] = useState(false);
  const reloadIframe = () =>
    setIframeReloadTrigger((trigger: boolean) => !trigger);

  useEffect(() => {
    onTestClick(); // run initially on load
  }, []);

  if (collapsed) {
    if (response?.length > 0) {
      return (
        <>
          <div>HTML Preview</div>
          <div></div>
          {/* <HTMLPreviewContainer
            html={truncateAfter(MAX_PREVIEW_CHARS,processedHTML)}
            reloadTrigger={iframeReloadTrigger}
            enabled={iframeEnabled}
          /> */}
        </>
      );
    } else {
      return <div>HTML Preview block</div>;
    }
  }

  return (
    <FuserLoader name="HTML Preview Block" loading={isLoading}>
      {runnerMode ? (
        block.data.response && block.data.response.length > 0 ? (
          <HTMLPreviewContainer
            html={processedHTML}
            reloadTrigger={iframeReloadTrigger}
            enabled={iframeEnabled}
          />
        ) : (
          <div>HTML Preview block</div>
        )
      ) : (
        <div className={blockStyles} key={index}>
          <label className="text-xs" id="prompt-textarea">
            HTML to preview:
          </label>

          <AutocompleteTextarea
            block={block}
            index={index}
            onChange={handleChange}
            className="w-full prompt-textarea bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner "
            name="inputToProcess"
            value={block.data.inputToProcess || ""}
          />

          <button onClick={onTestClick} className={testButtonStyles}>
            Test
          </button>

          {errorMessage === "" || <p>{errorMessage}</p>}

          <label>Result:</label>
          <HTMLPreviewContainer
            html={processedHTML}
            reloadTrigger={iframeReloadTrigger}
            enabled={iframeEnabled}
          />
        </div>
      )}
    </FuserLoader>
  );

  async function onTestClick() {
    setIframeEnabled(true);
    setIsLoading(true);

    const { inputToProcess } = block.data;

    let processedInput: string[] | string = inputToProcess
      ? replacePlaceholders(inputToProcess, blocks)
      : "";

    if (!Array.isArray(processedInput)) {
      processedInput = [processedInput];
    }

    reloadIframe();

    setBlocks((blocks: Block[]) => {
      const newBlocks = [...blocks];
      newBlocks[index] = {
        ...newBlocks[index],
        updatedBlock: runnerMode,
        data: {
          ...newBlocks[index].data,
          type: "html-preview",
          response: processedInput,
        },
      };
      return newBlocks;
    });

    setIsLoading(false);
    setActivityLog((prevLog: string[]) => [
      ...prevLog,
      `Saved HTML Preview block at index: ${index}`,
    ]);
  }
};

export default HTMLPreviewBlock;
