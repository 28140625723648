import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { buildCategoryTree } from '../utils/tree';
import { backendURL } from '../constants/environmental';
import EditableField from '../components/EditableField';
import Togglable from '../components/Togglable';

const CategoriesPage: FC<any> = () => {
  const authHeader = useAuthHeader()();

  const user = useAuthUser();
  const userId = user()?.id;
  const navigate = useNavigate();

  // redirects user if not logged in
  useEffect(() => {
    if (!userId) {
      console.log('Not logged in');
      navigate('/login');
    }
  });

  const [categories, setCategories] = useState<any[]>([]);

  const categoryTree = buildCategoryTree(categories);

  const getCategoryById = (id: string) => {
    const foundCategory = categories.find(({ _id }) => id === _id);
    if (!foundCategory) {
      return undefined;
    }
    return foundCategory;
  };

  const deleteCategory = (id: string) => async () => {
    const category = getCategoryById(id);
    const catName = category?.catName;
    if (!catName) {
      throw new Error(`No name found for category with id ${id}`);
    }
    const confirmed = prompt(
      `Click OK to delete the category '${catName}' (all subcategories will also be deleted)`
    );
    if (confirmed === null) return;
    try {
      const response: any = await axios.delete(
        `${backendURL}/admin/category/${id}`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      console.log(response);
      setCategories(response.data.newCategories);
    } catch (error) {
      console.error('Error deleting category', error);
    }
  };

  const editCategory =
    ({ id, parentId }: any) =>
    async () => {
      const newName = prompt('Enter the new category name here');
      if (newName === null) return;

      if (
        categories.some(
          ({ catName, parentCategory }) =>
            catName.toLowerCase() === newName.toLowerCase() &&
            parentCategory === parentId
        )
      )
        return alert('Name already taken');

      try {
        const response: any = await axios.patch(
          `${backendURL}/admin/category/${id}`,
          { newName, parentCategory: parentId },
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );
        console.log(response);
        setCategories(
          categories.map(category =>
            category._id === id ? { ...category, catName: newName } : category
          )
        );
      } catch (error) {
        console.error(error);
      }
    };

  const addCategory =
    ({ parentId = undefined } = {}) =>
    async () => {
      const name = prompt('Enter the new category name here');
      if (name === null) return;

      if (
        categories.some(
          ({ catName, parentCategory }) =>
            catName.toLowerCase() === name.toLowerCase() &&
            parentCategory === parentId
        )
      )
        return alert('Name already taken');

      const newCategory = {
        catName: name,
        ...(parentId ? { parentCategory: parentId } : {}),
      };

      try {
        const response: any = await axios.post(
          `${backendURL}/admin/category`,
          newCategory,
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );
        console.log(response);
        setCategories(response.data.categories);
      } catch (error) {
        console.error('Error adding category', error?.data?.message);
      }
    };

  const moveCategoryUp = (id: string) => async () => {
    try {
      const response: any = await axios.get(
        `${backendURL}/admin/categories/${id}/moveUp`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      console.log(response);
      setCategories(response.data.categories);
    } catch (error) {
      console.error('Error moving category', error?.data?.message);
    }
  };

  const moveCategoryDown = (id: string) => async () => {
    try {
      const response: any = await axios.get(
        `${backendURL}/admin/categories/${id}/moveDown`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      console.log(response);
      setCategories(response.data.categories);
    } catch (error) {
      console.error('Error moving category', error?.data?.message);
    }
  };

  const buildCategoryTreeElement = (categoryTree: any[]) => (
    <ul className='list-disc pl-8 my-2'>
      {categoryTree.map(({
        _id, catName, children, parentCategory, customUrlSlug, htmlDescription
      }, index) => (
        <li
          key={_id}
          className='my-2'
        >
          <div className='flex flex-col gap-2'>
            <div>
              <span className='font-black text-lg mr-2'>{catName}</span>
              <button
                className='border border-black px-2 mr-2'
                onClick={addCategory({ parentId: _id })}
              >
                +
              </button>
              <button
                className='border border-black px-2 mr-2'
                onClick={editCategory({ id: _id, parentId: parentCategory })}
              >
                ✎
              </button>
              {index !== categoryTree.length - 1 && (
                <button
                  className='border border-black px-2 mr-2'
                  onClick={moveCategoryDown(_id)}
                >
                  ↓
                </button>
              )}
              {index !== 0 && (
                <button
                  className='border border-black px-2 mr-2'
                  onClick={moveCategoryUp(_id)}
                >
                  ↑
                </button>
              )}
              <button
                className='border border-black px-2'
                onClick={deleteCategory(_id)}
              >
                X
              </button>
            </div>
            <div className='flex items-center gap-2'>
              <label>
                URL slug:
              </label>
              <EditableField
                initialValue={customUrlSlug ?? _id}
                inputType={'input'}
                onSave={updateUrlSlug(_id)}
              />
              <label>
                HTML description:
              </label>
              <EditableField
                initialValue={htmlDescription ?? ''}
                inputType={'textarea'}
                onSave={updateHtmlDescription(_id)}
              />
              <Togglable showText='Preview'>
                <div dangerouslySetInnerHTML={{
                  __html: htmlDescription ?? ''
                }}/>
              </Togglable>
            </div>
          </div>
          {children?.length > 0 && buildCategoryTreeElement(children)}
        </li>
      ))}
    </ul>
  );

  async function fetchCategories() {
    try {
      const response: any = await axios.get(`${backendURL}/admin/categories`, {
        headers: {
          Authorization: authHeader,
        },
      });
      console.log(response);
      setCategories(response?.data?.categories);
    } catch (error) {
      console.error('Error fetching categories', error);
    } finally {
    }
  }

  const updateUrlSlug = (id: string) => (newValue: string) => {
    const prettifiedUrlSlug = newValue
      .toLowerCase()
      .replace(/\s+/g, "-");
    
    return axios.patch(`${backendURL}/categories/${id}`,
      { customUrlSlug: prettifiedUrlSlug },
      {
        headers: {
          Authorization: authHeader
        }
      }
    );
  }

  const updateHtmlDescription = (id: string) => async (newValue: string) => {
    await axios.patch(`${backendURL}/categories/${id}`,
      { htmlDescription: newValue },
      {
        headers: {
          Authorization: authHeader
        }
      }
    );
    fetchCategories()
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div>
      <div className='container lg:pt-2 space-y-2 lg:space-y-4'>
        <h2 className='flex items-center text-xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
          Categories Page
        </h2>
        <div>
          {buildCategoryTreeElement(categoryTree)}{' '}
        </div>
        <button
          className='border border-black px-2 mr-2'
          onClick={addCategory()}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default CategoriesPage;
