import HeaderContext from '../../context/HeaderContext';
import Logo from '../../shared/Logo/Logo';
import SocialsList1 from '../../shared/SocialsList1/SocialsList1';
import { CustomLink } from '../../data/types';
import React, { useContext } from 'react';
import SwitchDarkMode from '../../shared/SwitchDarkMode/SwitchDarkMode';
import { useNavigate } from 'react-router-dom';

import { useAuthUser } from 'react-auth-kit';

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (!event.currentTarget.getAttribute('href')?.includes('http')) {
      event.preventDefault();

      const newPath = event.currentTarget.getAttribute('href');
      if (newPath) {
        navigate(newPath);
      }
    }
  };

  const user = useAuthUser()();
  const userId = user?.id;

  const discoverMenus = [{ href: '/page-search', label: 'Browse All Tools' }];

  if (userId !== undefined) {
    discoverMenus.push(
      {
        href: `/dashboard/${userId}?type=user`,
        label: 'Your Purchased Tools',
      },
      { href: `/dashboard/${userId}?type=creator`, label: 'Your Creations' },
      { href: '/fuser/0', label: 'Tool Maker' }
    );
  }

  discoverMenus.push({ href: '/sharedBlocks', label: 'Shared Blocks' });

  if (user?.loggedin === 'false') {
    discoverMenus.push(
      { href: `/admin-page/${userId}`, label: 'Admin page' },
      { href: `/sales-page/${userId}`, label: 'Sales page' },
      { href: `/categories-page`, label: 'Categories page' },
      { href: `/tracking-page`, label: 'Tracking page' },
      { href: `/bug-reports`, label: 'Bug reports' },
      { href: `/tool-responses`, label: 'Tool responses' }
    );
  }

  //console.log('userId', userId);
  const widgetMenus: WidgetFooterMenu[] = [
    {
      id: '5',
      title: 'Discover',
      menus: discoverMenus,
    },
    {
      id: '1',
      title: 'Help Centre',
      menus: [
        { href: '/about', label: 'About' },
        { href: '/credit-help', label: 'About Credits' },
        { href: 'https://blog.skillfusion.ai/', label: 'Blog' },
        //{ href: '/contact', label: 'Contact Us' },
        //{ href: "/users-help", label: "User's Guide" },
        //{ href: "/creators-help", label: "Creator's Guide" },
      ],
    },
    userId != undefined
      ? {
          id: '2',
          title: 'Settings',
          menus: [
            { href: `/buy-credits`, label: 'Buy Credits' },
            { href: `/profile/${userId}`, label: 'Your Profile' },
            { href: `/edit_account/${userId}`, label: 'Profile Settings' },
            { href: '/#logout', label: 'Logout' },
          ],
        }
      : {
          id: '2',
          title: 'Access',
          menus: [
            { href: '/login', label: 'Login' },
            { href: '/signup', label: 'Register' },
          ],
        },
  ];

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div
        key={index}
        className='text-sm'
      >
        <h2 className='font-semibold text-neutral-700 dark:text-neutral-200'>
          {menu.title}
        </h2>
        <ul className='mt-5 space-y-4'>
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className='text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white'
                href={item.href}
                rel=''
                onClick={handleLinkClick}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const { setReferAFriendModalOpen } = useContext(HeaderContext);
  return (
    <div className='flex flex-col items-center nc-Footer relative px-1 py-16 sm:mt-16 lg:pb-28 border-t border-neutral-200 dark:border-neutral-700'>
      {/* <button
        // className='font-bold mx-4 mb-10 border border-black border-1 p-2 rounded-lg bg-blue-100 hover:bg-blue-200 active:bg-blue-300'
        className='mx-4 mb-6 hover:bg-blue-200 active:bg-blue-300 underline'
        onClick={() => setReferAFriendModalOpen(true)}
      >
        Refer a friend and get 10 free credits
      </button> */}
      <div className='grid grid-cols-2 gap-3 justify-center mb-10 w-full'>
        <div className='w-full sm:w-44 max-h-72 flex justify-self-end'>
          <img
            className='object-cover'
            src={
              'https://blog.skillfusion.ai/wp-content/uploads/mikeBlackAndWhiteProfile.jpg'
            }
          />
        </div>
        <div className='footer-contact-details flex flex-col gap-4 sm:pt-1'>
          <div>
            <p>
            Would custom built Skillfusion Tools help your business? Contact Mike and his team.
            </p>
            <a
              className='text-blue-700 underline'
              href='https://aiagency.co.uk'
              target='_blank'
              rel='noreferrer'
            >
              Get In Touch With The Experts
            </a>
          </div>
          <p>
            For technical issues contact us via our{' '}
            <a
              className='text-blue-700 underline'
              href='https://www.facebook.com/skillfusionAI'
              target='_blank'
              rel='noreferrer'
            >
              Facebook Page chat.
            </a>
          </p>
        </div>
      </div>

      <div className='container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 '>
        <div className='grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col'>
          <div className='col-span-2 md:col-span-1'>
            <Logo />
          </div>
          <div className='col-span-2 flex items-center md:col-span-3'>
            <SocialsList1 className='flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start' />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
        <SwitchDarkMode />
      </div>
    </div>
  );
};

export default Footer;
