import React, { useState } from "react";

const Togglable = ({ showText = 'show', hideText = 'hide', children }: any) => {
  const [visible, setVisible] = useState(false);

  const buttonProps = {
    className: 'border border-1 border-black px-1',
    onClick: () => setVisible(previous => !previous),
  };

  return !visible
    ? <button {...buttonProps}>{showText}</button>
    : (
      <>
        {children}
        <button {...buttonProps}>{hideText}</button>
      </>
    );
};

export default Togglable;
