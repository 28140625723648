import React, { FC, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import FuserLoader from '../../containers/FuserPage/FuserLoader';
import BlockProps from '../../models/BlockProps';
import Block from '../../models/Block';
import FuserContext from '../../context/FuserContext';
import useBlockRunner from '../../hooks/useBlockRunner';
import AutocompleteTextarea from '../../containers/FuserPage/AutocompleteTextarea';
import { useCredit } from '../../context/CreditContext';
import { useAuthHeader } from 'react-auth-kit';
import { testButtonStyles } from '../../constants/styles';
import { backendURL } from '../../constants/environmental';
import { ensureArray, unflatten } from '../../utils/array';
import { truncateAfter } from '../../utils/string';
import { MAX_PREVIEW_CHARS } from '../../constants/blocks';
import { replacePlaceholders } from '../../utils/fuser';
import { defaultLanguageTag, regionLanguageMarketcodeTriples } from '../../constants/location';

const BingSearchBlock: FC<BlockProps> = ({
  isLoading,
  setIsLoading,
  index,
  block,
  handleChange,
  toolId,
  collapsed,
  updateBlocks,
}) => {
  const { blockStyles, runnerMode, blocks, setBlocks, isAuthor } =
    useContext(FuserContext);

  const { credit, updateCredits } = useCredit();
  const authHeader = useAuthHeader()();

  useBlockRunner(() => {}, index);

  useEffect(() => {
    if (!isAuthor && !block.data.marketCode) {
      block.data.marketCode = defaultLanguageTag;
      updateBlocks();
    }
  }, [])

  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => setErrorMessage(''), [runnerMode]);

  const fieldDisplayNames: Record<string, string> = {
    url: 'Link',
    name: 'Name',
    snippet: 'Snippet',
    displayUrl: 'Display Link',
    recentDays: 'Limit to recent results',
  };
  
  const {
    response,
    inputToProcess,
    numberOfResults,
    multipleChoiceAnswers,
    recencyOfResults,
    marketCode,
  } = block.data;

  const resultDisplay = (
    <textarea
      value={response}
      className='resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner h-36 overflow-y-scroll w-full'
      readOnly
    />
  );

  const searchRegionAndLanguageSection = (
    <label htmlFor='marketCode'>
      Search region & language:{' '}
      <select
        name='marketCode'
        value={marketCode}
        onChange={handleChange}
      >
        {regionLanguageMarketcodeTriples.map(([region, language, marketcode]) => (
          <option
            key={marketcode}
            value={marketcode}
          >
            {`${region}: ${language}`}
          </option>
        ))}
      </select>
    </label>
  );

  const searchButton = (
    <button
      onClick={onTestClick}
      className={testButtonStyles}
    >
      Search
    </button>
  );

  if (collapsed) {
    if (response?.length > 0) {
      return (
        <>
          <div>Bing search result</div>
          <p>{truncateAfter(MAX_PREVIEW_CHARS, response.toString())} </p>
        </>
      );
    }
    else {
      return <div>Bing Search block</div>;
    }
  }

  return (
    <FuserLoader
      name='Bing Search Block'
      loading={isLoading}
    >
      {runnerMode ? (
        <>
          {(
            response?.length > 0 ?
            <p>Results:</p> :
            <div>Bing Search block</div>
          )}
          {searchRegionAndLanguageSection}
          {searchButton}
          {response?.length > 0 && resultDisplay}
          <p>{errorMessage}</p>
        </>
      ) : (
        <div className={blockStyles}>
          <label
            className='text-xs'
            id='prompt-textarea'
          >
            Search terms:
          </label>

          <AutocompleteTextarea
            block={block}
            index={index}
            onChange={handleChange}
            className='w-full prompt-textarea bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
            name='inputToProcess'
            value={inputToProcess || ''}
          />

          <label htmlFor='numberOfResults'>
            Max number of results (up to 50, may return less):{' '}
            <input
              className='w-20'
              type='number'
              min='1'
              max='100'
              name='numberOfResults'
              pattern='^[0-9]{0,3}$'
              value={numberOfResults ?? 10}
              onChange={onNumberInputChange}
            />
          </label>

          <p>Fields to return (if they exist):</p>
          {Object.keys(fieldDisplayNames).map((key: string) => {
            const displayName = fieldDisplayNames[key];
            return (
              <div
                key={key}
                className='bg-transparent flex gap-1 items-center'
              >
                <input
                  type='checkbox'
                  id={`multiple-choice-answer-${key}`}
                  name='response'
                  value={key}
                  checked={multipleChoiceAnswers?.includes(key)}
                  onChange={handleCheckboxChange}
                />

                <label htmlFor='response'>{displayName}</label>

                {key !== 'recentDays' ? null : (
                  <>
                    {': '}
                    <select
                      className='h-8 py-0'
                      name='recencyOfResults'
                      value={recencyOfResults}
                      onChange={handleChange}
                    >
                      <option
                        key={'day'}
                        value={'day'}
                      >
                        1
                      </option>
                      <option
                        key={'week'}
                        value={'week'}
                      >
                        7
                      </option>
                      <option
                        key={'month'}
                        value={'month'}
                      >
                        30
                      </option>
                    </select>
                    {` ${recencyOfResults === 'day' ? 'day' : 'days'} ago `}
                  </>
                )}
              </div>
            );
          })}

          {searchRegionAndLanguageSection}

          {searchButton}

          {errorMessage === '' || <p>{errorMessage}</p>}

          {resultDisplay}
        </div>
      )}
    </FuserLoader>
  );

  async function onTestClick() {
    setErrorMessage('');
    setIsLoading(true);

    const {
      inputToProcess,
      numberOfResults,
      multipleChoiceAnswers,
      marketCode,
      recencyOfResults,
    } = block.data;

    const limitToRecentResults = multipleChoiceAnswers.includes('recentDays');

    const processedInput: any = ensureArray(
      inputToProcess ? replacePlaceholders(inputToProcess, blocks) : ''
    );

    const flatInputs = processedInput.flat(Infinity);

    try {
      if (flatInputs.length === 0) {
        throw new Error('Error: No search term entered');
      }

      const response = await axios.post(
        `${backendURL}/bing-search`,
        {
          searchTerms: flatInputs,
          numberOfResults: +numberOfResults,
          requestedFieldsToReturn: multipleChoiceAnswers,
          marketCode,
          toolId,
          ...(limitToRecentResults && { recencyOfResults })
        },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      if (response.data.updatedCredit != null) {
        updateCredits(response.data.updatedCredit * 10);
      }

      const results = response.data.searchResults
        .map((resultsForSearchTerm: any[]) => resultsForSearchTerm
          .map((result: any) => Object.keys(result)
            .map(key => `${fieldDisplayNames[key]}: ${result[key]}`)
            .join('\n')
          )
          .join('\n---\n')
        );

      setBlocks((blocks: Block[]) => {
        const newBlocks = [...blocks];

        newBlocks[index] = {
          ...newBlocks[index],
          updatedBlock: runnerMode,
          data: {
            ...newBlocks[index].data,
            type: 'bingSearch',
            response: unflatten(results, processedInput),
          },
        };
        return newBlocks;
      });
    }
    catch (error) {
      setErrorMessage(
        error?.response?.data?.error ??
        error?.message ??
        'There was an error performing this search'
      );
      console.error(error);
    }
    finally {
      setIsLoading(false);
    }
  }

  function onNumberInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.validity.valid) {
      handleChange(e);
    }
  }

  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { checked, value } = e.target;
    const { multipleChoiceAnswers } = block.data;

    block.data.multipleChoiceAnswers = (
      checked ?
      [...multipleChoiceAnswers, value] :
      multipleChoiceAnswers.filter((answer: string) => answer !== value)
    );

    updateBlocks();
  }
};

export default BingSearchBlock;
