import { ensureArray, ensureNotArray, filterInner, joinEverything, multiDimensionalArray, replaceHeadingsWithColumnLetterInExcelRange, updateArrayUsingExcelRange } from "./array";
import { processAtSigns, replacePlaceholders } from "./fuser";
import { escapeRegExpPattern, removeNumberPrefix } from "./string";
import { excelColumnToNumber, numberToExcelColumn } from './math';

function processBlockData({ block, blocks }: any) {
  const {
    inputToProcess,
    selectValue: processingType,
    captureGroupIndex
  } = block.data;

  let processedInput: multiDimensionalArray<string> | string =
    inputToProcess ? replacePlaceholders(inputToProcess, blocks) : '';

  if (Array.isArray(processedInput) && processedInput.length === 1) {
    processedInput = processedInput[0];
  }

  console.log('Processing input: ', processedInput);

  const splitDataForProcessingType: any = {
    'numbered-list': {
      //splitRegex = /(?:^|\n)\s*\d+[\.)\s]*/gm;
      // starts match at numbers followed by space/period/colon,
      // and ends match as the next one starts /
      // when a line containing just a line break is found /
      // at the end of the whole text
      splitRegex: /^(\d+[\s).:].+?)(?=\n\d+[\s).:]|(\n$)|(?!.))/gms,
      //splitRegex = /\n(\d+\.)/;
      splitType: 'match',
    },
    'line-breaks': {
      splitRegex: /\s*[\r\n]+\s*/g,
      splitType: 'split',
    },
    paragraph: {
      splitRegex: /\s*[\r\n]\s*[\r\n]\s*/g,
      splitType: 'split',
    },
    comma: {
      splitRegex: / *, */gm,
      splitType: 'split',
    },
    '400words': {
      splitRegex: /.{1,2000}$/gms,
      splitType: 'match',
    },
    '800words': {
      splitRegex: /.{1,4000}$/gms,
      splitType: 'match',
    },
    'custom-separator': {
      splitRegex: new RegExp(
        escapeRegExpPattern(
          ensureNotArray(
            replacePlaceholders(block.data.customSeparator, blocks)
          )
        ),
        'g'
      ),
      splitType: 'split',
    },
    'custom-match': {
      splitRegex: makeCustomRegex({ block, blocks }),
      splitType: 'match',
    },
    'find-remove': {
      splitRegex: makeCustomRegex({ block, blocks }),
      splitType: 'remove',
    },
    'split-x-characters': {
      splitType: 'slice'
    },
    undefined: {
      splitRegex: /^ *\d+[.) ]*/gm,
      splitType: 'split',
    },
  };

  let splitRegex: RegExp;
  let splitType: string;

  if (Object.keys(splitDataForProcessingType).includes(processingType)) {
    const splitData = splitDataForProcessingType[processingType];
    splitRegex = splitData.splitRegex;
    splitType = splitData.splitType;
  }
  else if (processedInput === undefined) {
    splitRegex = /^ *\d+[.) ]*/gm;
    splitType = 'split';
  }
  else {
    throw new Error('Invalid split type');
  }

  const deepProcess = (
    item: multiDimensionalArray<string> | string
  ): any => {
    if (typeof item !== 'string') return item?.map(deepProcess);

    if (splitType === 'split') {
      const splitItem = item
        .split(splitRegex)
        .map((splitItem: string) => splitItem.trim())
        .filter(splitItem => splitItem !== '');
      return splitItem;
    }

    if (splitType === 'match') {
      //item = item + '\n\n';

      // console.log(splitRegex, [...item.matchAll(splitRegex)]);
      const matches = captureGroupIndex
        ? [...item.matchAll(splitRegex)].map(
          (match: string[]) => match[captureGroupIndex]
        )
        : item.match(splitRegex);

      // console.log(item, captureGroupIndex, splitRegex, matches);
      const splitItems = matches?.map(removeNumberPrefix);
      return splitItems ?? [];
      // let splitItems: any = [];
      // let match;
      // while ((match = splitRegex.exec(item)) !== null) {
      //   console.log(match);
      //   splitItems.push(removeNumberPrefix(match[1]));
      // }
      // return splitItems ?? [];
    }
    
    if (splitType === 'remove') {
      // Remove all matches from the item and return the rest.
      const replacedItem = item.replace(
        splitRegex,
        replacePlaceholders(block.data.newValue, blocks)
      );
      return replacedItem;
    }
    
    if (processingType === 'split-x-characters') {
      const numberOfCharacters = +block.data.numberOfCharacters.match(/\d+/);
      if (numberOfCharacters <= 0) {
        throw new Error('Invalid number of characters');
      }
      const chunks: string[] = [];
      for (
        let chunkStart = 0;
        chunkStart < item.length;
        chunkStart += numberOfCharacters
      ) {
        chunks.push(item.slice(chunkStart, chunkStart + numberOfCharacters))
      }
      return chunks;
    }
  };

  let response = deepProcess(processedInput);

  if (splitType === 'remove' && block.data.removeEmptyElements) {
    response = filterInner(
      (x: any) => ![null, undefined, ''].includes(x),
      response
    );
  }

  console.log('Processing response:', response);
  
  block.data.response = response;
}

function modifyBlockData({ block, blocks, spreadsheetRefs }: any) {
  const { referenceModifier } = block.data;

  const blockIndicesToModify = getBlockIndicesToModify(block, blocks);

  let returnedValue;

  for (const indexOfBlockToModify of blockIndicesToModify) {
    const blockToModify = blocks[indexOfBlockToModify];

    const oldResponse = blockToModify.data.response;
    if (referenceModifier === 'clear') {
      blockToModify.data.response = '';
      
      if (blockToModify.blocktype === 'chat') {
        blockToModify.data.inputToProcess = '';
      }
    }
    else if (referenceModifier === 'append') {
      let outputToAppend: string | multiDimensionalArray<string> =
        replacePlaceholders(block.data.appendSecondOperand, blocks);
      
      if (Array.isArray(outputToAppend)) {
        outputToAppend = joinEverything(', ', outputToAppend);
      }
      blockToModify.data.response += outputToAppend;

    } else if (referenceModifier === 'append-list') {

      let outputToAppend = replacePlaceholders(block.data.appendSecondOperand, blocks);
    
      // Ensure both outputToAppend and blockToModify.data.response are arrays
      if (!Array.isArray(outputToAppend)) {
        outputToAppend = [outputToAppend];
      }

      let fist_part = blockToModify.data.response;

      if (!Array.isArray(fist_part)) {
        fist_part = [fist_part];
      }
    
      // Concatenate the arrays
      blockToModify.data.response = fist_part.concat(outputToAppend);
      
    }
    else if (referenceModifier === 'keep-start') {
      blockToModify.data.response = oldResponse.slice(0, block.data.cropAmountString);
    }
    else if (referenceModifier === 'keep-end') {
      blockToModify.data.response = oldResponse.slice(-block.data.cropAmountString);
    }
    else if (
      referenceModifier === 'remove-words-from-start-and-set-as-response'
    ) {
      const words = oldResponse.trim().split(/\s+/);

      returnedValue = words
        .slice(0, block.data.numberOfWordsToRemoveFromStart)
        .join(' ');

      blockToModify.data.response = words.slice(block.data.numberOfWordsToRemoveFromStart).join(' ');
    }
    else if (
      referenceModifier === 'remove-first-match-and-set-as-response'
    ) {
      const matchRegex = new RegExp(
        block.data.removeFirstMatchPattern,
        block.data.removeFirstMatchFlags
      );

      const match = matchRegex.exec(oldResponse);

      if (match !== null) {
        const matchedText = match[0];
        returnedValue = matchedText;
        const newResponse = (
          oldResponse.slice(0, match.index) +
          oldResponse.slice(match.index + matchedText.length)
        );
        blockToModify.data.response = newResponse;
      }
    } 
    else if (referenceModifier === 'set-values-of-spreadsheet-range') {
      let spreadsheet = blockToModify.data.response || [];
      let newValues = ensureArray(replacePlaceholders(
        block.data.newSpreadsheetValues, blocks
      ));

      // make sure newValues is a 2d array
      if (!Array.isArray(newValues[0])) {
        newValues = [newValues];
      }

      const matchHeadings = blockToModify.data.useColumnHeadingReferences;

      let range = block.data.spreadsheetRange?.trim();

      if (range && matchHeadings) {
        range = replaceHeadingsWithColumnLetterInExcelRange({
          headings: spreadsheet[0], range
        });
      }
      
     
      if (!range) {
        // No range specified; append data to the spreadsheet
      
        if (block.data.newColumnHeading) {
          if (block.data.transposeNewValues) {
            newValues = newValues.map(row => [
              block.data.newColumnHeading, ...row
            ])
          }
          else {
            const headingRow = Array.from(
              { length: newValues[0].length },
              () => block.data.newColumnHeading
            );
            newValues = [headingRow, ...newValues]
          }
        }
        // Determine the dimensions of the existing spreadsheet
        const numRows = spreadsheet.length;

        const numCols = !spreadsheet[0]?.[0] ? 0 : Math.max(
          ...spreadsheet.map((row: any[]) => {
            let numTrailingEmptyCells = 0;
            for (let i = row.length - 1; i >= 0; i--) {
              if (row[i]) return row.length - numTrailingEmptyCells;
              numTrailingEmptyCells++;
            }
            return row.length - numTrailingEmptyCells;
          }
        ));

        const numEmptyTrailingCols = (
          !spreadsheet[0] ? 0 : spreadsheet[0].length - numCols
        );

        // Determine the dimensions of dataToInsert
        //const numNewRows = block.data.transposeNewValues? newValues.length : newValues[0].length;
        //const numNewCols = block.data.transposeNewValues? newValues[0].length : newValues.length;

        const numNewRows = block.data.transposeNewValues? newValues[0].length : newValues.length;
        const numNewCols = block.data.transposeNewValues? newValues.length : newValues[0].length;
      
        // Decide where to place the new data
        // For appending new columns to the right of existing columns
        const startRow = 1;
        const startCol = numCols;
      
        // Expand the spreadsheet array to accommodate dataToInsert
        const totalRows = Math.max(numRows, numNewRows);
        const totalCols = !spreadsheet[0] ? numNewCols : spreadsheet[0].length + numNewCols - numEmptyTrailingCols;
      
        for (let i = 0; i < totalRows; i++) {
          // Initialize missing rows
          if (!spreadsheet[i]) {
            spreadsheet[i] = [];
          }
      
          // Expand each row to the total number of columns
          for (let j = 0; j < totalCols; j++) {
            if (spreadsheet[i][j] === undefined) {
              spreadsheet[i][j] = '';
            }
          }
        }
      
        // Set the range value based on where the new data will be inserted
        const startColRef = numberToExcelColumn(startCol+1);
        const endColRef = numberToExcelColumn(startCol+numNewCols);
        range = `${startColRef}1:${endColRef}${numNewRows}`;
        console.log(range);
      }      

      updateArrayUsingExcelRange({
        range,
        newValues,
        array: spreadsheet,
        transposeNewValues: block.data.transposeNewValues,
        matchHeadings,
      });

      // delete empty rows from the bottom
      for (let i = spreadsheet.length - 1; i >= 0; i--) {
        if (spreadsheet[i].every((cell: any) => !cell)) continue;
        spreadsheet = spreadsheet.slice(0, i + 1);
        break;
      }

      // delete empty columns from the right
      for (let i = spreadsheet[0].length - 1; i >= 0; i--) {
        if (spreadsheet.every((row: any) => !row[i])) continue;
        spreadsheet = spreadsheet.map((row: any) => row.slice(0, i + 1));
        break;
      }

      spreadsheetRefs[indexOfBlockToModify].current = spreadsheet;
    }
    else if (
      ['increment', 'decrement', 'multiply-by'].includes(referenceModifier)
    ) {
      const extractChangeAmountRegex = /-?\d+/;

      let changeAmount = block.data.changeAmountString?.match(
        extractChangeAmountRegex
      )?.[0];
      if (changeAmount !== undefined) {
        changeAmount = +changeAmount;
      } else {
        changeAmount = 1;
      }

      const numberToModifyMatch = oldResponse.match(/\d+/);

      if (numberToModifyMatch) {
        const number = parseInt(numberToModifyMatch[0], 10);

        blockToModify.data.response = oldResponse.replace(
          number.toString(),
          (
            referenceModifier === 'increment'
            ? number + changeAmount
            : referenceModifier === 'decrement'
            ? number - changeAmount
            : referenceModifier === 'multiply-by'
            ? number * changeAmount
            : ''
          ).toString()
        );
      }
    }
    else {
      throw new Error(
        `Invalid reference modifier: ${referenceModifier}`
      );
    }

    const newResponse = blockToModify.data.response;

    if (
      typeof newResponse !== 'string' &&
      referenceModifier !== 'set-values-of-spreadsheet-range'
    ) {
      blockToModify.data.response = (
        newResponse === undefined ?
        '' :
        Array.isArray(newResponse) && newResponse.length === 0 ?
        '' :
        //newResponse?.[0] // why set the modified block to just the first item?
        newResponse

      )
    } 


    // blocks[index === blockIndex ? 'updatedBlock' : 'saveQueued'] = true;
  }

  return { blocks: [...blocks], returnedValue };
}

function sendBlockInputToChatBlock(block: any, blocks: any) {  
  const { inputToProcess, referenceModifier, chatBlockNumber } = block.data;

  const indexOfChatBlock = +(
    chatBlockNumber ??
    // default to the first chat block
    blocks.findIndex(
      ({ type }: { type: string }) => type === 'blockdiv-chat'
    )
  );

  const chatBlock = blocks[indexOfChatBlock];

  const extraInfo = replacePlaceholders(inputToProcess, blocks).toString();

  const { temporaryExtraInfo } = chatBlock.data;

  if (referenceModifier === 'send-to-chat-block-temporary') {
    chatBlock.data.temporaryExtraInfo =
      (temporaryExtraInfo ?? '') + extraInfo;
  }

  if (referenceModifier.startsWith('send-to-chat-block-permanent')) {
    chatBlock.data.newPermanentInfo = extraInfo;
    if (referenceModifier.endsWith('dont-process')) {
      chatBlock.data.dontSendToOpenAI = true;
    }
  }

  return { blocks: [...blocks], returnedValue: undefined };
}

const makeCustomRegex = ({ block, blocks }: any) => {
  let flags = block.data.customMatchFlags;
  if (block.data.captureGroupIndex && !flags.includes('g')) {
    // ensure the regex is global since matchAll in this case
    flags += 'g';
  }
  const processedMatchPattern = ensureNotArray(
    replacePlaceholders(block.data.customMatchPattern, blocks)
  );
  // console.log(processedMatchPattern);
  return new RegExp(processedMatchPattern, flags);
};

function getBlockIndicesToModify(block: any, blocks: any) {
  const blockNumberRegex = /<(\d+):output>/g;

  const blockIndicesToModifyIterator = processAtSigns(
    block.data.inputToProcess,
    blocks
  )?.matchAll(blockNumberRegex);

  return (
    blockIndicesToModifyIterator === undefined ?
    [] :
    [...blockIndicesToModifyIterator].map(
      ([match, blockNumberString]: any) => +blockNumberString
    )
  );
}

export { sendBlockInputToChatBlock, processBlockData, modifyBlockData }