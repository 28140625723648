import ReactGA from "react-ga4";
import axios from "axios";
import { getBrowser, getOperatingSystem } from "./userDeviceInfo";
import { backendURL } from "../constants/environmental";

export interface gptParamsType {
  userId?: string;
  toolId?: string;
  prompt?: string | string[];
  model?: string;
  temperature?: number;
  topP?: number;
  frequencyPenalty?: number;
  presencePenalty?: number;
  maximumWordsInResponse?: number;
  responseSchema?: string | string[];
  temporaryExtraInfo?: string;
}

const MAX_PROMPT_BATCH_SIZE = 20;
const MAX_RETRIES = 5;
const MAX_RESPONSE_WAIT_TIME_IN_SECONDS = 120;
const MAX_WORDS_FOR_MODEL: any = {
  turbo: 1500,
  gpt4o: 1500,
  gpt4: 1000,
  llama2: 750,
  o1: 75_000,
  'o1-mini': 15_000,
};

const defaultInputValues: any = {
  maximumWordsInResponse: {
    turbo: 800,
    gpt4o: 800,
    gpt4: 600,
    llama2: 400,
    o1: 5000,
    'o1-mini': 5000,
  },
};

function retryPromptCondition(
  status: number,
  statusText: string,
  code: string
) {
  return (
    [502, 503].includes(status) ||
    (status === 429 && statusText?.startsWith("Rate limit reached")) ||
    statusText?.startsWith("Too Many Requests") ||
    (status === 500 && statusText?.startsWith("The server had an error")) ||
    code === "ERR_NETWORK"
  );
}

function sendReactGaRetryEvent(status: number, code: string) {
  if (status === 429) {
    ReactGA.event("token_limit_retry");
  }
  if (status === 500) {
    ReactGA.event("openai_server_error_retry");
  }
  if (status === 502) {
    ReactGA.event("502_error_retry");
  }
  if (status === 503) {
    ReactGA.event("openai_server_overloaded_retry");
  }
  if (code === "ERR_NETWORK") {
    ReactGA.event("network_error");
  }
}

async function handleAxiosError(axiosError: any, errorContext: any) {
  //console.log('Handling axios error');
  const response = axiosError?.response;
  const status = response?.status;
  const statusText = response?.statusText;
  const message = response?.data?.error?.message;

  //console.log('error object recieved:', errorObj);

  switch (status) {
    case 400: {
      ReactGA.event("bad_openai_request");
      throw new Error(
        response?.error ||
          "Error: Bad request to OpenAI API. Try reducing the prompt/input size."
      );
    }
    case 401: {
      ReactGA.event("unauthorized_openai_call");
      throw new Error(
        "Error: Unauthorized request. Please check that you are logged in."
      );
    }
    case 402: {
      throw new Error("Error: You have insufficient credit.");
    }
    // @ts-ignore: fallthrough is intentional
    case 429: {
      if (statusText?.startsWith("You exceeded your current quota")) {
        ReactGA.event("monthly_openai_limit_reached");
        throw new Error("Error: Monthly limit reached");
      }
    }
    default: {
      // ReactGA.event('express_error_' + status);
      if (status && message) {
        throw new Error(
          `Error: Status ${status} ${message ? ` - ${message}` : ""}`
        );
      } else {
        // unknown error, log to database
        try {
          const errorDetails = {
            error: axiosError,
            browser: getBrowser(),
            operatingSystem: getOperatingSystem(),
            ...errorContext,
          };
          await axios.post(`${backendURL}/errorDetails`, {
            errorDetails,
          });
        } catch (e) {
          console.log("Unknown error:", e);
        }
        throw new Error(
          "An unknown error occurred, please contact support if the error persists."
        );
      }
      //console.error('Error from Backend API:', axiosError, axiosError?.response?.data);
    }
  }
}

export {
  handleAxiosError,
  retryPromptCondition,
  sendReactGaRetryEvent,
  MAX_WORDS_FOR_MODEL,
  MAX_PROMPT_BATCH_SIZE,
  MAX_RETRIES,
  MAX_RESPONSE_WAIT_TIME_IN_SECONDS,
  defaultInputValues,
};
