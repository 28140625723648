import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header/Header';
import Header2 from '../components/Header/Header2';

const SiteHeader = () => {
  const location = useLocation();

  return location.pathname === '/home2' ? <Header2 /> : <Header />;
};

export default SiteHeader;
