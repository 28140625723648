import React, { FC, useState, useEffect, useMemo, useContext } from 'react';
import { FiPlus } from 'react-icons/fi';
import FuserLoader from '../../containers/FuserPage/FuserLoader';
import BlockProps from '../../models/BlockProps';
import { useCredit } from '../../context/CreditContext';
import Papa from 'papaparse';
import FuserContext from '../../context/FuserContext';
import Block from '../../models/Block';
import useBlockRunner from '../../hooks/useBlockRunner';
import useShallowEqualMemo from '../../hooks/useShallowEqualMemo';
import ReactTextareaAutosize from 'react-textarea-autosize';
import AutocompleteTextarea from '../../containers/FuserPage/AutocompleteTextarea';
import { useAuthHeader } from 'react-auth-kit';
import { iconStyle, testButtonStyles } from '../../constants/styles';
import {
  deleteAtIndex,
  multiDimensionalArray,
  updateAtIndex,
  flatten,
  unflatten,
  ensureArray,
} from '../../utils/array';
import { cosinesim } from '../../utils/math';
import { getOrdinalSuffix } from '../../utils/string';
import { replacePlaceholders } from '../../utils/fuser';
import { defaultEmbeddingsFilterNumber } from '../../constants/blocks';
import { backendURL } from '../../constants/environmental';
import axios from 'axios';
import AutosizeTextarea from '../../containers/FuserPage/AutosizeTextarea';

const EmbeddingsBlock: FC<BlockProps> = ({
  isLoading,
  setIsLoading,
  index,
  resultHtml,
  block,
  updateBlockData,
  handleChange,
  updateBlocks,
}) => {
  const {
    userId,
    blockStyles,
    runnerMode,
    blocks,
    setBlocks,
    setActivityLog,
    restartQueued,
    textAreaRefs,
    // runnerIndex,
  } = useContext(FuserContext);

  const authHeader = useAuthHeader()();
  const { credit, updateCredits } = useCredit();

  // const blockResponses = useMemo(
  //   () => blocks.map((block: Block) => block.data),
  //   [blocks]
  // );

  useEffect(() => {
    if (!block.data.nextResultIndex) block.data.nextResultIndex = 0;
    updateBlocks();
  }, []);

  useEffect(() => {
    if (!restartQueued) return;
    block.data.nextResultIndex = 0;
    updateBlocks();
  }, [restartQueued]);
  
  const defaultStoredEmbeddings = {
    answerEmbeddings: [],
    outputEmbeddings: [],
  };

  const [storedEmbeddings, setStoredEmbeddings] = useState<{
    answerEmbeddings: number[][];
    outputEmbeddings: number[][];
  }>(defaultStoredEmbeddings);

  const [highestSimilarity, setHighestSimilarity] = useState<number|undefined>();

  const {
    inputToProcess,
    multipleChoiceAnswers,
    multipleChoiceOutputs,
    checkedRadioValue,
    selectValue: responseType,
    filterNumber,
    startResultIndex,
    model,
    useDefaultEmbedding,
    defaultEmbeddingThreshold,
    defaultEmbedding,
  } = block.data;

  const multipleChoiceAnswersMemo = useShallowEqualMemo(
    multipleChoiceAnswers ?? []
  );
  const multipleChoiceOutputsMemo = useShallowEqualMemo(
    multipleChoiceOutputs ?? []
  );

  const customOutputsUsedMemo = useMemo(() => {
    return block.data.checkedRadioValue;
  }, [block]);

  useEffect(()=>{
    if (block.data.optionsSource) return;
    block.data.optionsSource = 'define-options';
    updateBlocks();
  }, []);

  useEffect(() => {
    console.log('clearing stored embeddings');
    setStoredEmbeddings(defaultStoredEmbeddings);
  }, [
    multipleChoiceAnswersMemo,
    multipleChoiceOutputsMemo,
    customOutputsUsedMemo,
    model,
  ]);

  const customOutputsUsed = block.data.checkedRadioValue === 'yes';

  // test pausing this so it shouldn't run this block
  useBlockRunner(onTestClick, index);

  const [answerFormVisible, setAnswerFormVisible] = useState<boolean>(false);
  const [answerFormInputValues, setAnswerFormInputValues] = useState<{
    answer: string;
    output: string;
  }>({
    answer: '',
    output: '',
  });

  const [answerBeingEdited, setAnswerBeingEdited] = useState<number | false>(
    false
  );
  const [answerError, setAnswerError] = useState<string>('');

  const [errorMessage, setErrorMessage] = useState<string>('');

  const radioGroupName = `radio-group-${index}`;

  const {
    selectValue,
    // response
  } = block.data;

  // console.log(response);

  return (
    <FuserLoader
      name='Embeddings Block'
      loading={isLoading}
    >
      {runnerMode ? (
        <div>Analysing internal data</div>
      ) : (
        <div
          className={blockStyles}
          key={index}
        >
          <label
            className='text-xs'
            id='prompt-textarea'
          >
            Text to compare:
          </label>

          <AutocompleteTextarea
            block={block}
            index={index}
            onChange={handleChange}
            className='w-full prompt-textarea bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
            name='inputToProcess'
            value={inputToProcess || ''}
          />

          {
            <>
              <label className='text-xs w-max-content'>
                Model:
                <select
                  className='text-xs bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
                  onChange={handleChange}
                  value={model}
                  name='model'
                >
                  <option value='text-embedding-ada-002'>ada v2</option>
                  <option value='text-embedding-3-small'>ada v3 small</option>
                  <option value='text-embedding-3-large'>ada v3 large</option>
                </select>
              </label>
              <p>Answer/Output Options:</p>
              <select
                className='text-sm bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
                name='optionsSource'
                value={block.data.optionsSource} 
                onChange={handleChange}
              >
                <option value='define-options'>Define options here</option>
                <option value='options-from-block'>Take options from a block</option>
              </select>
              {block.data.optionsSource === 'options-from-block' &&
                <div>
                  <label 
                    htmlFor="embeddingsBlockReference"
                    className='flex items-center gap-2'
                  >
                    Enter block reference here:
                    <AutocompleteTextarea
                    name='embeddingsBlockReference'
                    block={block}
                    index={index}
                    onChange={handleChange}
                    containerClassName='w-36 flex items-center'
                    className='h-10 w-full bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                    value={block.data.embeddingsBlockReference}
                    />
                  </label>
                </div> 
              }
              {block.data.optionsSource === 'define-options' && (
                [0, undefined].includes(multipleChoiceAnswers?.length) ? (
                  <p>No answer/output options added yet...</p>
                ) : (
                <table className='max-h-80 table-auto border-separate inline-block overflow-y-scroll '>
                  <thead>
                    <tr>
                      <th>Answer</th>
                      {customOutputsUsed && <th>Output</th>}
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {multipleChoiceAnswers?.map(
                      (option: string, choiceIndex: number) => (
                        <tr key={choiceIndex}>
                          <td>{option}</td>

                          {customOutputsUsed && (
                            <td>{multipleChoiceOutputs[choiceIndex]}</td>
                          )}

                          <td>
                            <button onClick={handleEditClick(choiceIndex)}>
                              Edit
                            </button>
                          </td>

                          <td>
                            <button onClick={handleDeleteClick(choiceIndex)}>
                              Delete
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              ))}
            </>
          }

          {block.data.optionsSource === 'define-options' && (answerFormVisible ? (
            answerBeingEdited !== false ? (
              <>
                <div className='flex items-center justify-between'>
                  <div className='flex gap-3 items-center justify-between p-2'>
                    <label>Answer:</label>

                    <AutosizeTextarea
                      className='bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner'
                      name='answer'
                      value={answerFormInputValues?.answer}
                      onChange={handleAnswerInputFormChange}
                      block={block}
                    />

                    {customOutputsUsed && (
                      <>
                        <label>Output:</label>

                        <AutosizeTextarea
                          className='bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner'
                          name='output'
                          value={answerFormInputValues?.output}
                          onChange={handleAnswerInputFormChange}
                          block={block}
                        />
                      </>
                    )}
                    <button
                      className='border-solid border-2 border-black p-2'
                      onClick={handleUpdateAnswer}
                    >
                      Update
                    </button>
                    <button
                      className='border-solid border-2 border-black p-2'
                      onClick={handleDiscardChanges}
                    >
                      Discard changes
                    </button>
                  </div>
                </div>
                {answerError === '' || <p>{answerError}</p>}
              </>
            ) : (
              <>
                <div className='flex items-center justify-between'>
                  <div className='flex flex-col md:flex-row gap-3 items-center justify-between p-2'>
                    <AutosizeTextarea
                      className='bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner'
                      name='answer'
                      placeholder='Enter the answer here'
                      value={answerFormInputValues?.answer}
                      onChange={handleAnswerInputFormChange}
                      block={block}
                    />
                    {customOutputsUsed && (
                      <AutosizeTextarea
                        className='bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner'
                        name='output'
                        placeholder='Enter the output here'
                        value={answerFormInputValues?.output}
                        onChange={handleAnswerInputFormChange}
                        block={block}
                      />
                    )}
                    <div className='flex gap-4'>
                      <button onClick={handleAddAnswer}>Add</button>
                      <button onClick={handleDiscardChanges}>Discard</button>
                    </div>
                  </div>
                </div>
                {answerError === '' || <p>{answerError}</p>}
              </>
            )
          ) : (
            <div className='flex gap-2 items-center'>
              <button
                className='bg-blue-200 dark:bg-neutral-900 dark:text-neutral-200 p-2 rounded-xl text-xs cursor-pointer hover:bg-blue-300 hover:shadow-lg transform hover:-translate-y-1 transition-all duration-200 ease-in-out'
                onClick={showAnswerForm}
              >
                <FiPlus className={`${iconStyle}`} />
              </button>
              <p>Add answer/output option</p>

              <button
                className='bg-blue-200 dark:bg-neutral-900 dark:text-neutral-200 p-2 rounded-xl text-sm cursor-pointer hover:bg-blue-300 hover:shadow-lg transform hover:-translate-y-1 transition-all duration-200 ease-in-out'
                onClick={clearMultipleChoiceAnswersAndOutputs}
              >
                Clear answers/outputs
              </button>
            </div>
          ))}

          {block.data.optionsSource === 'define-options' && (
            <label className='w-max bg-blue-200 dark:bg-neutral-900 dark:text-neutral-200 p-2 rounded-xl text-sm cursor-pointer hover:bg-blue-300 hover:shadow-lg transform hover:-translate-y-1 transition-all duration-200 ease-in-out'>
              <input
                type='file'
                accept='.csv'
                name='uploadedFileName'
                onChange={handleFileChange}
                className='hidden'
              />
              Upload CSV
            </label>
          )}

          {
            <fieldset>
              <legend>Use custom outputs?</legend>

              <div className='flex gap-2 items-center'>
                <input
                  type='radio'
                  id='no'
                  name={radioGroupName}
                  value='no'
                  checked={
                    checkedRadioValue === 'no' ||
                    checkedRadioValue === undefined
                  }
                  onChange={onRadioChange}
                />
                <label htmlFor={radioGroupName}>No</label>
              </div>

              <div className='flex gap-2 items-center'>
                <input
                  type='radio'
                  id='yes'
                  name={radioGroupName}
                  value='yes'
                  checked={checkedRadioValue === 'yes'}
                  onChange={onRadioChange}
                />
                <label htmlFor={radioGroupName}>Yes</label>
              </div>
            </fieldset>
          }

          <div className='flex flex-col md:flex-row gap-2 items-center'>
            <select
              className='text-sm bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
              name='selectValue'
              value={selectValue}
              onChange={onSelectChange}
            >
              <option value='top-results'>Return the top</option>
              <option value='max-words'>
                Return as many results that fit into
              </option>
              <option value='next-results'>Return the next</option>
              <option value='random-results'>Return random results</option>
            </select>

            <div className='flex items-center gap-2'>
              <input
                className='w-28 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                type='number'
                name='filterNumber'
                value={filterNumber ?? 1}
                onChange={onNumberInputChange}
              />
              <span>
                {selectValue === 'top-results'
                  ? `result${+filterNumber === 1 ? '' : 's'}`
                  : selectValue === 'next-results'
                    ? `result${
                        +filterNumber === 1 ? '' : 's'
                      }`
                    : selectValue === 'random-results'
                      ? `(${+filterNumber} random result${
                          +filterNumber === 1 ? '' : 's'
                        })`
                      : selectValue === 'max-words'
                        ? `word${+filterNumber === 1 ? '' : 's'}`
                        : `This span content is undefined for the following select value: ${selectValue}`}
              </span>

                {
                  selectValue === 'next-results' && !runnerMode && (
                    <button
                      onClick={() => {
                        block.data.nextResultIndex = 0;
                        updateBlocks();
                      }}
                      className='w-max bg-blue-200 dark:bg-neutral-900 dark:text-neutral-200 p-2 rounded-xl text-sm cursor-pointer hover:bg-blue-300 hover:shadow-lg transform hover:-translate-y-1 transition-all duration-200 ease-in-out'
                    >
                      Reset
                    </button>
                  )
                }
              {/* {selectValue === 'next-results' && (
                <AutocompleteTextarea
                  autosize={true}
                  block={block}
                  index={index}
                  onChange={handleChange}
                  containerClassName='w-36 flex items-center'
                  className='w-full bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='startResultIndex'
                  value={startResultIndex ?? 0}
                />
              )} */}
            </div>
          </div>
          {selectValue === 'top-results' && +filterNumber === 1 && (
            <div className='flex gap-2 items-center'>
              <input
                type='checkbox'
                checked={block.data.useDefaultEmbedding}
                onChange={() => {
                  block.data.useDefaultEmbedding =
                    !block.data.useDefaultEmbedding;
                  updateBlocks();
                }}
              />
              Use a default value if all similarities are under{' '}
              <input
                type='number'
                min='-1'
                step='0.01'
                max='1'
                className='w-20 bg-transparent rounded-xl text-sm border border-black-100'
                name='defaultEmbeddingThreshold'
                onChange={handleChange}
                value={block.data.defaultEmbeddingThreshold}
              />
              Default value:
              <input
                type='text'
                className='bg-transparent rounded-xl text-sm border border-black-100'
                name='defaultEmbedding'
                onChange={handleChange}
                value={block.data.defaultEmbedding}
                />
              {highestSimilarity && (
                <span>Highest similarity: {highestSimilarity}</span>
              )}
            </div>
          )}

          <button
            onClick={onTestClick}
            className={testButtonStyles}
          >
            Test
          </button>

          <p>{errorMessage}</p>

          <div
            className='overflow-x-auto'
            dangerouslySetInnerHTML={{ __html: `Result: ${resultHtml}` }}
          />
        </div>
      )}
    </FuserLoader>
  );

  interface adaParamsType {
    authHeader: string;
    userId: string;
    model: string;
    input: string | string[];
  }

  async function ada_embeddings({
    authHeader,
    userId = '',
    input,
    model,
  }: adaParamsType) {
    const inputArray = !Array.isArray(input) ? [input] : input;
    const cleanedInputs = inputArray.map(element =>
      element.replace('[\n\r]+/gm', ' | ').slice(0, 12000)
    );
    const totalInputTokens = cleanedInputs.join('').length / 4;
    if (totalInputTokens > 8191) {
      const error = `Payload too large for ada_embeddings: ${totalInputTokens} tokens`;
      console.error(error);
      return error;
    }

    const response = await axios.post(
      `${backendURL}/openai/adaEmbeddings`,
      { userId, inputs: cleanedInputs, model: model || undefined },
      {
        headers: {
          Authorization: authHeader,
        },
        withCredentials: true,
      }
    );
    return response.data;
  }

  async function onTestClick() {
    //console.log(block.data);
    setIsLoading(true);

    let response: any;

    let processedInput: multiDimensionalArray<string> = inputToProcess
      ? replacePlaceholders(inputToProcess, blocks)
      : '';

    if (typeof processedInput === 'string') {
      processedInput = [processedInput];
    }

    setErrorMessage('');
    //console.log('inputToProcess', inputToProcess);

    let answers: any, outputs: any;

    if (block.data.optionsSource === 'options-from-block') {
      const processedBlock = ensureArray(replacePlaceholders(block.data.embeddingsBlockReference, blocks));
      if (!customOutputsUsed) answers = processedBlock;
      else {
        answers =  processedBlock[0];
        outputs = processedBlock[1];
      }
    }
    else {
      answers = multipleChoiceAnswers;
      outputs = multipleChoiceOutputs;
    }

    if (responseType === 'random-results') {
      let tempArr: any = [];
      if (customOutputsUsed) tempArr = [...outputs];
      else tempArr = [...answers];

      // Shuffle array
      for (let i = tempArr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [tempArr[i], tempArr[j]] = [tempArr[j], tempArr[i]];
      }

      // Get first 'size' elements
      // tk need to get answers??????
      response = tempArr.slice(0, filterNumber);
    } else if (responseType === 'next-results' && !inputToProcess) {
      // return results in the order they are defined
      const numberOfResults = +filterNumber;

      const { nextResultIndex } = block.data;

      const nextResults = (
        customOutputsUsed ? outputs : answers
      ).slice(
        nextResultIndex,
        nextResultIndex + numberOfResults
      );

      block.data.nextResultIndex += numberOfResults;
      response = Array.isArray(nextResults) && nextResults.length === 1
        ? nextResults[0]
        : nextResults;
    } else {
      if (answers?.length === 0) {
        setErrorMessage('Please add some answer/output options');
        response = [];
        return;
      }

      const inputWords = flatten(processedInput);

      //console.log(inputWords);

      if (
        inputWords?.length === 1 &&
        inputWords[0] === '' &&
        responseType !== 'next-results'
      ) {
        setIsLoading(false);
        return setErrorMessage('Please add some text to compare');
      }

      let embeddings;
      let embeddingsResponse;
      try {
        embeddingsResponse = (await ada_embeddings({
          authHeader,
          model,
          userId: userId ?? '',
          input: [
            ...inputWords,
            ...(storedEmbeddings.answerEmbeddings.length !== 0
              ? []
              : answers),
            ...(storedEmbeddings.outputEmbeddings.length !== 0
              ? []
              : customOutputsUsed
                ? outputs
                : []),
          ],
        })) || [''];
      } catch (error) {
        const errorObj = error as { message: string };

        const updatedCreditInDollars = error?.response?.data?.updatedCredit;
        console.log({ error });
        if (updatedCreditInDollars !== undefined) {
          updateCredits(updatedCreditInDollars * 10);
        }

        setErrorMessage(errorObj.message ?? 'Error with GPT response');
        setIsLoading(false);
        return;
      }

      embeddings = embeddingsResponse.embeddings;
      // console.log(embeddings);
      // update credit
      if (embeddingsResponse.updatedCredit != null) {
        updateCredits(embeddingsResponse.updatedCredit * 10);
      }
      // console.log(
      //   'embeddingsResponse',
      //   embeddingsResponse.updatedCredit,
      //   embeddingsResponse
      // );
      // handle malformed request
      if (typeof embeddings === 'string') {
        const error = embeddings;
        setIsLoading(false);
        return setErrorMessage(error);
      }

      setErrorMessage('');

      const inputWordsEmbeddings: number[][] = embeddings.slice(
        0,
        inputWords.length
      );

      const answerEmbeddings: number[][] =
        storedEmbeddings.answerEmbeddings.length !== 0
          ? storedEmbeddings.answerEmbeddings
          : embeddings.slice(
              inputWords.length,
              inputWords.length + answers.length
            );

      const outputEmbeddings: number[][] =
        storedEmbeddings.outputEmbeddings.length !== 0
          ? storedEmbeddings.outputEmbeddings
          : embeddings.slice(inputWords.length + answers.length);

      setStoredEmbeddings({
        answerEmbeddings,
        outputEmbeddings,
      });

      // handle malformed response
      for (const embedding of embeddings)
        if (typeof embedding === 'string') {
          setIsLoading(false);
          return setErrorMessage(`Invalid embedding: ${embedding}`);
        }

      setErrorMessage('');

      const answerSimilaritiesToInputs = inputWordsEmbeddings.map(
        inputWordEmbedding =>
          answerEmbeddings.map(answerEmbedding =>
            cosinesim(inputWordEmbedding, answerEmbedding)
          )
      );

      let largestSimilaritiesForEachInput: any;

      let bestAnswersDescendingForEachInput;

      if (customOutputsUsed) {
        bestAnswersDescendingForEachInput = answerSimilaritiesToInputs.map(
          (answerSimilarites, similaritiesIndex) => {
            const outputSimilaritiesToInputs = inputWordsEmbeddings.map(
              inputWordEmbedding =>
                // subtract 0.02 as they are generally less relevant than inputs
                outputEmbeddings.map(
                  outputEmbedding =>
                    cosinesim(inputWordEmbedding, outputEmbedding) - 0.02
                )
            );
            largestSimilaritiesForEachInput = inputWords.map((_, i) =>
              Math.max(
                ...answerSimilaritiesToInputs[i],
                ...outputSimilaritiesToInputs[i]
              )
            );
            const outputSimilarities =
              outputSimilaritiesToInputs[similaritiesIndex];
            const answersAndOutputsWithSimilarities: {
              answer: string;
              output: string;
              similarity: number;
            }[] = [];

            for (let i = 0; i < answers.length; i++) {
              answersAndOutputsWithSimilarities.push({
                answer: answers[i],
                output: outputs[i],
                similarity: Math.max(
                  answerSimilarites[i],
                  outputSimilarities[i]
                ),
              });
            }

            const answersAndOutputsWithSimilaritiesDescending = answersAndOutputsWithSimilarities
              .sort((obj1, obj2) => obj2.similarity - obj1.similarity);
            
            setHighestSimilarity(answersAndOutputsWithSimilaritiesDescending[0].similarity);
            
            return answersAndOutputsWithSimilarities.map(obj => obj.output);
          }
        );
      } else {
        largestSimilaritiesForEachInput = inputWords.map((_, i) =>
          Math.max(...answerSimilaritiesToInputs[i])
        );
        bestAnswersDescendingForEachInput = answerSimilaritiesToInputs.map(
          similarityArray => {
            const answersWithSimilarities: {
              answer: string;
              similarity: number;
            }[] = [];

            for (let i = 0; i < answers.length; i++) {
              answersWithSimilarities.push({
                answer: answers[i],
                similarity: similarityArray[i],
              });
            }

            const answersWithSimilaritiesDescending = answersWithSimilarities
              .sort((obj1, obj2) => obj2.similarity - obj1.similarity);
            
            setHighestSimilarity(answersWithSimilaritiesDescending[0].similarity);
              
            return answersWithSimilaritiesDescending.map(obj => obj.answer);
          }
        );
      }

      console.log({bestAnswersDescendingForEachInput});

      if (responseType === 'top-results') {
        const numberOfResults = +filterNumber;
        const topAnswersForEachInput = bestAnswersDescendingForEachInput.map(
          (answersDescending, i) => {
            if (numberOfResults === 1) {
              if (useDefaultEmbedding) {
                return largestSimilaritiesForEachInput[i] >=
                  defaultEmbeddingThreshold
                  ? answersDescending[0]
                  : defaultEmbedding;
              } else {
                return answersDescending[0];
              }
            } else {
              return answersDescending.slice(0, filterNumber);
            }
          }
        );

        console.log({
          filterNumber,
          numberOfResults,
          bestAnswersDescendingForEachInput,
          topAnswersForEachInput,
        });
        response = unflatten(topAnswersForEachInput, processedInput);
      }

      if (responseType === 'max-words') {
        const maxWords = +filterNumber;
        const flatResponse = bestAnswersDescendingForEachInput.map(
          answersDescending => {
            const responsesSoFar: string[] = [];
            let wordsSoFar = 0;
            for (const answer of answersDescending) {
              wordsSoFar += answer
                .split(/\s+/g)
                .filter(word => word !== '').length;
              if (wordsSoFar <= maxWords) {
                responsesSoFar.push(answer);
              } else break;
            }
            return responsesSoFar.length === 1
              ? responsesSoFar[0]
              : responsesSoFar;
          }
        );

        response = unflatten(flatResponse, processedInput);
      }

      if (responseType === 'next-results') {
        // return results in order of similarity to the input
        const numberOfResults = +filterNumber;

        const { nextResultIndex } = block.data;

        const flatNextResultsForEachInput =
          bestAnswersDescendingForEachInput.map(answersDescending => {
            const nextAnswers = answersDescending.slice(
              nextResultIndex,
              nextResultIndex + numberOfResults
            );
            return Array.isArray(nextAnswers) && nextAnswers.length === 1
              ? nextAnswers[0]
              : nextAnswers;
          });

        block.data.nextResultIndex += numberOfResults;
        response = unflatten(flatNextResultsForEachInput, processedInput);
      }
    }

    // console.log('response', response);
    if (Array.isArray(response?.[0])) {
      response = [response[0].join('\r\n')];
    }
    // console.log('response', response);

    setBlocks((blocks: Block[]) => {
      const newBlocks = [...blocks];
      newBlocks[index] = {
        ...newBlocks[index],
        updatedBlock: runnerMode,
        data: {
          ...newBlocks[index].data,
          type: 'embeddings',
          response,
        },
      };
      return newBlocks;
    });

    setIsLoading(false);
    setActivityLog((prevLog: string[]) => [
      ...prevLog,
      `Saved embedding block at index: ${index}`,
    ]);

    console.log('finished embedding block now');
  }

  function createMultipleChoiceAnswer(answer: string, customOutput = '') {
    answer = answer.trim();
    customOutput = customOutput?.trim(); // may be undefined

    const {
      multipleChoiceAnswers,
      multipleChoiceOutputs,
      //checkedRadioValue
    } = block.data;

    if (answer === '') {
      setAnswerError('Please enter an answer');
      return false;
    }

    if (customOutputsUsed && customOutput === '') {
      setAnswerError('Please enter an output');
      return false;
    }

    const indexOfClash = multipleChoiceAnswers.findIndex(
      (answerInputValue: string) => answer === answerInputValue
    );

    if (indexOfClash !== -1) {
      setAnswerError(
        `This is already the ${getOrdinalSuffix(indexOfClash + 1)} option.`
      );
      return false;
    } else {
      block.data.multipleChoiceAnswers = [...multipleChoiceAnswers, answer];

      if (customOutputsUsed)
        block.data.multipleChoiceOutputs = [
          ...multipleChoiceOutputs,
          customOutput,
        ];

      updateBlocks();
      setAnswerError('');
      setErrorMessage('');
      return true;
    }
  }

  function updateMultipleChoiceAnswer(
    indexToUpdate: number,
    newAnswer: string,
    newCustomOutput = ''
  ) {
    newAnswer = newAnswer.trim();
    newCustomOutput = newCustomOutput?.trim(); // may be undefined

    const {
      multipleChoiceAnswers,
      multipleChoiceOutputs,
      //checkedRadioValue
    } = block.data;

    if (newAnswer === '') {
      setAnswerError('Please enter an answer');
      return false;
    }

    if (customOutputsUsed && newCustomOutput === '') {
      setAnswerError('Please enter an output');
      return false;
    }

    const indexOfClash = multipleChoiceAnswers.findIndex(
      (answerInputValue: string, inputIndex: number) =>
        indexToUpdate !== inputIndex && newAnswer === answerInputValue
    );

    if (indexOfClash !== -1) {
      setAnswerError(
        `This is already the ${getOrdinalSuffix(indexOfClash + 1)} option.`
      );
      return false;
    } else {
      block.data.multipleChoiceAnswers = updateAtIndex(
        indexToUpdate,
        newAnswer,
        multipleChoiceAnswers
      );

      if (customOutputsUsed) {
        block.data.multipleChoiceOutputs = updateAtIndex(
          indexToUpdate,
          newCustomOutput,
          multipleChoiceOutputs
        );
      }

      updateBlocks();
      return true;
    }
  }

  function handleAddAnswer() {
    const { answer, output } = answerFormInputValues;
    if (createMultipleChoiceAnswer(answer, output)) {
      clearAnswerFormInputs();
      hideAnswerForm();
    }
  }

  function handleUpdateAnswer() {
    const { answer, output } = answerFormInputValues;

    if (answerBeingEdited === false) {
      console.log('Error: handleUpdateAnswer called with false');
    } else if (updateMultipleChoiceAnswer(answerBeingEdited, answer, output)) {
      setAnswerError('');
      setAnswerBeingEdited(false);
      clearAnswerFormInputs();
      hideAnswerForm();
    }
  }

  function handleDeleteClick(index: number) {
    return () => {
      deleteMultipleChoiceAnswer(index);

      const { multipleChoiceAnswers, response } = block.data;

      if (response === multipleChoiceAnswers[index]) {
        block.data.response = undefined;
      }

      setAnswerBeingEdited(false);
      clearAnswerFormInputs();
      hideAnswerForm();
    };
  }

  function deleteMultipleChoiceAnswer(indexToDelete: number) {
    const { multipleChoiceAnswers, multipleChoiceOutputs } = block.data;

    block.data.multipleChoiceAnswers = deleteAtIndex(
      indexToDelete,
      multipleChoiceAnswers
    );
    block.data.multipleChoiceOutputs = deleteAtIndex(
      indexToDelete,
      multipleChoiceOutputs
    );

    updateBlocks();
  }

  function handleEditClick(index: number) {
    return () => {
      const { multipleChoiceAnswers, multipleChoiceOutputs } = block.data;

      setAnswerBeingEdited(index);
      setAnswerFormInputValues({
        answer: multipleChoiceAnswers[index],
        output: multipleChoiceOutputs[index],
      });

      showAnswerForm();
    };
  }

  function clearAnswerFormInputs() {
    setAnswerFormInputValues({ answer: '', output: '' });
  }

  function showAnswerForm() {
    setAnswerFormVisible(true);
  }

  function hideAnswerForm() {
    setAnswerFormVisible(false);
  }

  function clearMultipleChoiceAnswersAndOutputs() {
    block.data.multipleChoiceAnswers = [];
    block.data.multipleChoiceOutputs = [];
    updateBlocks();
  }

  function handleDiscardChanges() {
    setAnswerBeingEdited(false);
    clearAnswerFormInputs();
    hideAnswerForm();
  }

  function handleAnswerInputFormChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { name, value } = e.target;
    setAnswerFormInputValues({ ...answerFormInputValues, [name]: value });
  }

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];

    if (file) {
      Papa.parse(file, {
        complete: (result: Papa.ParseResult<string[]>) => {
          const column1Arr: string[] = [];
          const column2Arr: string[] = [];

          result.data.forEach((row: string[]) => {
            column1Arr.push(row[0]);
            column2Arr.push(row[1]);
          });

          block.data.multipleChoiceAnswers.push(...column1Arr);
          if (column2Arr.some(item => item !== undefined)) {
            block.data.multipleChoiceOutputs.push(...column2Arr);
            block.data.checkedRadioValue = 'yes';
          } else {
            block.data.checkedRadioValue = 'no';
          }
          updateBlocks();
        },
      });
    }

    console.log('finished parsing file');

    e.target.value = '';
    // need to force some kind of refresh here
    //updateBlocks();
    //handleChange(e);
    //updateBlocks();
    //handleAddAnswer();
  }

  function onSelectChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const filterNumber = e.target.value;

    updateBlockData(
      'filterNumber',
      defaultEmbeddingsFilterNumber[filterNumber]
    );
    handleChange(e);
  }

  function onNumberInputChange(e: React.ChangeEvent<any>) {
    if (e.target.validity.valid) {
      handleChange(e);
    }
  }

  function onRadioChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value === 'yes') {
      block.data.multipleChoiceOutputs = block.data.multipleChoiceAnswers;
      updateBlocks();
      handleChange(e);
    } else {
      block.data.multipleChoiceOutputs = [];
      handleChange(e);
    }
  }

  /*
  const processCSV = (str:String) => {
    const arr = str.split("\n");
    const column1Arr: string[] = [];
  const column2Arr: string[] = [];
    let hasSecondColumn = false;

    arr.forEach((line) => {
      const [col1, col2] = line.split(",");
      column1Arr.push(col1);

      if (col2 !== undefined) {
        hasSecondColumn = true;
        column2Arr.push(col2);
      } else {
        column2Arr.push(''); // or some default value
      }

    });

    return {column1Arr, column2Arr};
  };
  */
};

export default EmbeddingsBlock;
