import React, { useContext } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import FuserContext from '../../context/FuserContext';

const AutosizeTextarea = ({
  block,
  className,
  name,
  value,
  onChange,
  placeholder
}: {
  block: any;
  className?: string;
  name: string;
  value: any;
  onChange: any;
  placeholder?: string;
}) => {
  const textareaId = `${block.id}:${name}`;
  const { textAreaRefs, handleTextareaFocus } = useContext(FuserContext);

  return (
    <ReactTextareaAutosize
      className={className}
      name={name}
      value={value}
      onFocus={(event: any) => {
        handleTextareaFocus(event, block.id, textareaId);
      }}
      onChange={onChange}
      ref={(el: any) => {
        textAreaRefs.current[textareaId] = el;
      }}
      placeholder={placeholder}
    />
  );
};


export default AutosizeTextarea;
