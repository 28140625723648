import React from "react";
import { Link } from "react-router-dom";

const linkClass = 'text-decoration-line underline text-blue-700 active:text-red-700 visited:text-purple-700';

function CompetitionPage() {
  return (
    <div>
      <div className='container lg:pt-2 space-y-2 lg:space-y-4 flex flex-col items-center gap-2'>
        <h2 className='text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100'>
          Competition
        </h2>

        <h3 className="text-xl font-semibold text-indigo-700">
          Monetize Your AI Skills
        </h3>

        <div className="flex flex-col gap-2">
          <p className="text-center">
            You can make and sell AI tools using our{' '}
            <Link to='/fuser/0' className={linkClass}>
              free tool builder
            </Link>
          </p>
  
          <p className="text-center">
            Whoever makes the best tool by 7th Feb will win a $200 Prize!
          </p>
        </div>

        <h3>Checkout the video to see how easy it is. </h3>
        
          <iframe
            className='max-w-560 sm:w-1/2 h-72 sm:h-80'
            src="https://www.youtube.com/embed/Um00PRX6JUM?si=qNPSVEvDkipJ_Gbd" 
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />

        <ul className="list-style-none flex flex-col gap-2">
          <li>➡️Benefit from learning how to make AI tools</li>
          <li>➡️Sell anything you make on our marketplace</li>
          <li>➡️And be in with a chance of winning $200.</li>
        </ul>

        <div className="flex flex-col gap-2">
          <p className="text-center">
            <Link to='/maker-help' className={linkClass}>
              Learn more on how to make tools
            </Link>
          </p>

          <p className="text-center">
            We also have a{' '}
            <a
              href='https://www.udemy.com/course/make-and-sell-chatgpt-powered-ai-tools/'
              className={linkClass}
              target="_blank"
            >
              free udemy course
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default CompetitionPage;
