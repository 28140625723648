import React, { FC } from "react";
// import MyToolTips from '../../components/MyTooltip';

interface Props {
  gptModel: string;
  index: number;
  handleModelChange: any;
}

const GptModelSelect: FC<Props> = ({ handleModelChange, gptModel, index }) => {
  return (
    <label className="text-xs w-max-content flex gap-1 items-center">
      GPT Model:
      {/* <MyToolTips
        content='
          <p>
            GPT Models:
            <br />
            - Turbo 3.5 is the same model as the current free version of 
            chatGPT, it is very cheap and it can process up to 12,000 words.
            <br />
            - GPT-4 is about 40x more expensive than Turbo. It is a text 
            completion model so you can start the answer you want and it will 
            complete it, as opposed to replying. It can be helpful if turbo is 
            not producing the answer you wish.
            <br />
            - GPT-4o 
          </p>
        '
        tipID={index.toString()}
        datatooltipplace='below'
      /> */}
      <select
        className="text-xs bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner "
        onChange={handleModelChange}
        value={gptModel}
        name="gptModel"
      >
        <option value="turbo">Turbo 3.5</option>
        <option value="gpt4o">GPT-4o</option>
        <option value="o1">o1</option>
        <option value="o1-mini">o1 mini</option>
        <option value="gpt4">GPT-4</option>
        <option value="llama2">LLaMA-2</option>
      </select>
    </label>
  );
};

export default GptModelSelect;
