import zapierBlockImg2 from 'images/zapier-creator-3.jpg';
import { useNavigate } from 'react-router-dom';
import { YoutubeAccordionInfo } from '../../containers/NftDetailPage/AccordionInfo';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import BgGlassmorphism from '../../components/BgGlassmorphism/BgGlassmorphism';
import SectionHero, { VideoHero } from './SectionHero';
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
// import SectionFounder from './SectionFounder';
// import SectionStatistic from './SectionStatistic';
// import BackgroundSection from '../../components/BackgroundSection/BackgroundSection';
// import SectionClientSay from '../../components/SectionClientSay/SectionClientSay';

export interface PageAboutProps {
  className?: string;
}

const MakerHelp: FC<PageAboutProps> = ({ className = '' }) => {
  const navigate = useNavigate();

  const handleLinkClick = (event: any) => {
    // Prevent the default link behavior
    event.preventDefault();
    const newPath = event.currentTarget.getAttribute('href');
    // Navigate to the link href
    navigate(newPath);
  };

  const description_block = `Example Videos:

  https://www.youtube.com/watch?v=PtSLqlxxvjI
  
  `;

  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id='PageAbout'
    >
      <Helmet>
        <title>About || Skillfusion.ai</title>
        <link
          rel='canonical'
          href='https://skillfusion.ai/maker-help'
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>
        <VideoHero
          videoUrl={
            'https://www.youtube.com/embed/21zYepA4PvE?si=HjCv2coUYW-UkNC0'
          }
          heading='How To Make Tools'
          btnText=''
          rightImg=''
          leftText={false}
          subHeading="Watch this video for an introduction on how to make your own AI Tools.<br/><br/>

          You can use the tools yourself or publish them on the site. <br/><br/>
          
          Published tools can be free to purchase, or you can sell them to users for either a one off price or a monthly fee. You get 100% on all sales.<br/><br/>
          
          Using the different blocks available in our block-based no-code builder you can:<br/><br/>
          <ul style='list-style:inside;'>
          <li>Send prompts to chatgpt</li>
          <li>Scrape websites</li>
          <li>Store and retrieve info and examples that the tool needs</li>
          <li>Generate images with Dall-e</li>
          <li>Connect to Zapier </li></ul><br/>
          
          All the tools on this site were made using the Tool Maker.       
          
          "
        />

        <div
          className={`nc-SectionHero relative ${className}`}
          data-nc-id='SectionHero'
        >
          <div className='flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left'>
            <div className='w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7'>
              <h2 className='text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100'>
                Blocks
              </h2>
              <span className='block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400'>
                Check the links for help on a particular block:
                <ul style={{ listStyleType: 'disc', listStyle: 'inside' }}>
                  <li>
                    {' '}
                    <a
                      href='/chat-help'
                      style={{ color: 'cornflowerblue' }}
                      onClick={handleLinkClick}
                    >
                      Chatbot Block
                    </a>
                  </li>
                  <li>
                    {' '}
                    <a
                      href='/embeddings-help-c'
                      style={{ color: 'cornflowerblue' }}
                      onClick={handleLinkClick}
                    >
                      Embeddings Block
                    </a>
                  </li>
                  <li>
                    {' '}
                    <a
                      href='/zapier-help-c'
                      style={{ color: 'cornflowerblue' }}
                      onClick={handleLinkClick}
                    >
                      Zapier Block
                    </a>
                  </li>
                  <li>more help coming soon...</li>
                </ul>
                <br />
                <br />
              </span>
            </div>
            <div className='flex-grow'>
              <img
                className='w-full'
                src={zapierBlockImg2}
                alt=''
              />
            </div>
          </div>
        </div>

        <div
          className={`nc-SectionHero relative ${className}`}
          data-nc-id='SectionHero'
        >
          <div className='flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left'>
            <div className='w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7'>
              <h2 className='text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100'>
                Making Quality Tools
              </h2>
              <span className='block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400'>
                Quality tools can: <br />
                <ul style={{ listStyle: 'inside' }}>
                  <li>Produce better output</li>
                  <li>Solve more complex problems</li>
                  <li>Output in a style that is unique to your tool</li>
                  <li>
                    Produce longer output than you can get form a single prompt
                  </li>
                  <li>Asks the right questions of the user</li>
                </ul>
                <br /> <br />
                Find out more on quality{' '}
                <a
                  href='/quality-help'
                  style={{ color: 'cornflowerblue' }}
                  onClick={handleLinkClick}
                >
                  here
                </a>
                <br />
                <br />
                <p>
                  We also have a free Udemy course{' '}
                  <a
                    href='https://www.udemy.com/course/make-and-sell-chatgpt-powered-ai-tools/'
                    style={{ color: 'cornflowerblue' }}
                  >
                    here
                  </a>
                </p>
              </span>
            </div>
            <div className='flex-grow'>
              <iframe
                className='w-full'
                width='560'
                height='315'
                src='https://www.youtube.com/embed/KHHGixkWABY?si=tyoTZrVtW8b5kPt6'
                title='YouTube video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>

        <YoutubeAccordionInfo description={description_block} />
      </div>
    </div>
  );
};

export default MakerHelp;
