'use client';
import { ErrorBoundary } from 'react-error-boundary';
import React, { FC, useContext, ChangeEvent } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import BlockOptions from './BlockOptions';
import FuserContext from '../../context/FuserContext';
import Block from '../../models/Block';
import MyToolTips from '../../components/MyTooltip';
import { blockComponents, initialBlockData } from '../../constants/blocks';
import {
  prettyPrintMultidimensionalStringArray,
  updateAtIndex,
} from '../../utils/array';
import { truncateAfter } from '../../utils/string';

// import TestErrorBoundary from '../../components/TestErrorBoundary';

const BlockFallback = ({ error }: any) => (
  <div className='gap w-full h-48 flex items-center justify-center'>
    Sorry, this block has crashed
    {error?.message ? ` with the following error: ${error.message}` : ''}
  </div>
);

const BlocksFallback = ({ error }: any) => (
  <div className='gap w-full h-48 flex items-center justify-center'>
    Sorry, this tool has crashed
    {error?.message ? ` with the following error: ${error.message}` : ''}
  </div>
);

const onBlockError = (error: Error) => {
  ReactGA.event('block_crashed', {
    errorMessage: error?.message,
    stack: error?.stack,
  });
};

const onBlocksError = (error: Error) => {
  ReactGA.event('tool_crashed', {
    errorMessage: error?.message,
    stack: error?.stack,
  });
};

const isHiddenInRunnerMode = (block: any) => {
  return (
    [
      'blockdiv-processing',
      'blockdiv-reducing',
      'blockdiv-custom-javascript',
    ].includes(block?.type) ||
    (block?.type === 'blockdiv-info' && block?.data?.selectValue !== 'external')
  );
};

const BlocksDisplay: FC<{}> = () => {
  const {
    blocks,
    setBlocks,
    collapsedBlocks,
    runnerMode,
    isJsonVisible,
    setIsLoading,
    stillRunning,
    isLoading,
    updateBlockData,
    handleChange,
    imageURLsForBlockWithId,
    runnerIndex,
    blockRefs,
    minimalMode,
    // blockStyles,
  } = useContext(FuserContext);

  const location = useLocation();

  const renderBlock = (block: Block, index: number): JSX.Element | null => {
    const path = location.pathname;
    const pathParts = path.split('/');
    let lastSegment = pathParts.pop() || pathParts.pop(); // handle potential trailing slash
    if (lastSegment === undefined) lastSegment = 'PROBLEM GETTING TOOLid';
    //console.log("last segment in fusr",lastSegment,path,location.pathname,pathParts);

    const response = block?.data?.response ?? undefined;

    const { type } = block;

    // console.log({ minimalMode });

    if (Object.keys(blockComponents).includes(type)) {
      const Block = blockComponents[type].component;
      //console.log("in fuser",lastSegment);
      const blockClasses = `border-2 my-4 p-4 flex ${
        runnerMode &&
        collapsedBlocks.includes(blocks[index].id) &&
        block.type !== 'blockdiv-textToSpeech'
          ? 'flex-row'
          : 'flex-col'
      } rounded-xl gap-2 text-black shadow-xl bg-gradient-to-b ${
        runnerMode && index === runnerIndex ? 'border-blue-500 border' : ''
      } ${
        !runnerMode || index === runnerIndex
          ? 'from-blue-100 to-purple-200'
          : 'from-blue-50 to-purple-100'
      } '${!minimalMode ? 'transition-opacity duration-1000 ease-in-out opacity-0 animate-fade-in' : ''} dark:bg-neutral-800 ${
        runnerMode &&
        (isHiddenInRunnerMode(block) ||
          (minimalMode && collapsedBlocks.includes(blocks[index].id)))
          ? ' hidden'
          : ''
      }`;

      let resultHtml = '';
      if (response !== undefined) {
        try {
          resultHtml = truncateAfter(
            1000,
            Array.isArray(response)
              ? prettyPrintMultidimensionalStringArray(response)
              : response.toString()
          ).replace(
            /<(\/?)(script|style)>/g,
            (match: string, closingSlash: string, tagName: string) =>
              `&lt;${closingSlash}${tagName}&gt;`
          );
        } catch (error) {
          console.log('Error processing resultHtml:', error);
        }
      }

      return (
        <ErrorBoundary
          FallbackComponent={BlockFallback}
          onError={onBlockError}
          key={index}
        >
          {/* For testing block error boundary: {index === 1 && <TestErrorBoundary />} */}

          <div
            className={blockClasses}
            ref={el => {
              blockRefs.current[index] = el;
            }}
          >
            {/* <FuserLoader loading={true} /> */ null}

            <Block
              block={block}
              index={index}
              imageURLs={imageURLsForBlockWithId[block.id]}
              toolId={lastSegment}
              stillRunning={stillRunning}
              updateBlockData={updateBlockData(index)}
              updateBlocks={updateBlocks}
              isLoading={isLoading?.[index]}
              handleChange={e => handleChange(index, e)}
              setIsLoading={(newValue: boolean) =>
                setIsLoading(updateAtIndex(index, newValue, isLoading))
              }
              resultHtml={resultHtml}
              tool_id={lastSegment}
              collapsed={collapsedBlocks.includes(block.id)}
            />
            <BlockOptions
              block={block}
              index={index}
            />

            {isJsonVisible ? (
              <pre className='border-2 p-2 rounded-xl bg-white'>
                {JSON.stringify(block, null, 2)}
              </pre>
            ) : (
              <p></p>
            )}
          </div>
        </ErrorBoundary>
      );
    } else
      return (
        <div
          key={index}
          className='gap w-full h-48 flex items-center justify-center transition-all duration-500 ease-in-out transform hover:translate-y-[-10px] cursor-pointer'
        >
          <p>Please select block type:</p>
          <select
            className='text-xs w-40 h-16 rounded-lg bg-transparent'
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              changeBlockType(index, e.target.value)
            }
          >
            <option value=''>choose block type</option>
            {Object.keys(blockComponents).map((key, i) => (
              <option
                value={key}
                key={i}
              >
                {blockComponents[key].selectName}
              </option>
            ))}
          </select>
          <MyToolTips
            content="<p>block types</p>
            <p>There are many block types to choose from.
            <p>Usually you will want some <q>User Question</q> blocks early on. To gather information on the user's needs. e.g. a fitness tool may ask what their goals are.</p>
            <p>The <q>prompt</q> block allows you to send prompts to the AI.</p>
            <p>Embeddings blocks are for storing and searching for information, as a way of giving your tool knowledge, so you can add a lot of info and search for the bits you need based on the user's input.</p> 
            <p>If you want to show the user a little message at the start of your tool choose an <q>info block</q> and set it to <q>show to user</q></p>"
            tipID={index.toString() + '-block-types-definition'}
            datatooltipplace='left'
          />
        </div>
      );

    function updateBlocks() {
      return setBlocks((blocks: Block[]) =>
        updateAtIndex(index, block, blocks)
      );
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={BlocksFallback}
      onError={onBlocksError}
    >
      {blocks?.map((block: Block, index: number) => renderBlock(block, index))}
    </ErrorBoundary>
  );

  function changeBlockType(index: number, newType: string) {
    setBlocks((blocks: Block[]) => {
      const newBlocks = [...blocks];
      newBlocks[index] = {
        ...newBlocks[index],
        type: newType,
        data: initialBlockData[newType],
      };
      return newBlocks;
    });
  }
};

export default BlocksDisplay;
