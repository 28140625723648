import React, { FC, useState, useEffect, createContext, useRef } from 'react';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import PayPalSection from './PayPalSection';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import PaymentOptionCard from './PaymentOptionCard';
import {
  currenciesThatDontSupportDecimals,
  languageToCurrency,
  supportedCurrencyCodeNamePairs,
  unconvertedCurrencies,
} from '../../constants/currency';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import Select from 'react-select';
import { backendURL } from '../../constants/environmental';
import guarantee from '../../images/guarantee.jpg';
import credentials from '../../images/credentials.png';
import payFiction from '../../images/pay-fiction.jpg';
import paySEO from '../../images/pay-seo.jpg';
import payLogos from '../../images/pay-logos.jpg';
// import { hotjar } from 'react-hotjar';
// hotjar.initialize({ id: 5031132, sv: 6 });
// hotjar.identify('USER_ID', { userProperty: 'value' });
// import exitIntent from 'exit-intent';
import BuyCreditsExitIntentDialog from '../../components/BuyCreditsExitIntentDialog';
import { useNavigate } from 'react-router-dom';
import ModalContainer from '../../components/ModalContainer';
import SignupModalSequence from '../../components/SignupModalSequence';

const SANDBOX_MODE = false;

//const currenciesOnlySupportedInCountry = ['CNY', 'MYR'];
const approximateExchangeRateFromDollarsTo: Record<string, number> = {
  GBP: 0.8,
  AUD: 1.55,
  EUR: 0.93,
  USD: 1,
};

const formatCurrencyRoundedUp = (value: number, currency: string) => {
  const locale = navigator?.language || 'en-US';
  // Round up to 2 decimal places or nearest integer if decimals are unsupported
  const roundedValue = currenciesThatDontSupportDecimals.includes(currency)
    ? Math.ceil(value)
    : Math.ceil(value * 100) / 100;

  // Format as currency
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(roundedValue);
};

// const creditsToCurrency = ({
//   credits,
//   exchangeRateFromDollars,
// }: {
//   credits: number;
//   exchangeRateFromDollars: any;
// }) => (credits / 10) * exchangeRateFromDollars;

// const removeWindowListener = exitIntent({
//   threshold: 0,
//   maxDisplays: Infinity,
//   eventThrottle: 500,
//   onExitIntent: () => {
//     if (exitIntentShown) {
//       return;
//     }
//     setExitIntentShown(true);
//     console.log('Exit intent: window', exitIntentShown);
//   },
// });

// const removePageListener = exitIntent({
//   threshold: 70,
//   maxDisplays: Infinity,
//   eventThrottle: 1000,
//   onExitIntent: () => {
//     if (exitIntentShown || window.scrollY > 35) {
//       return;
//     }
//     setExitIntentShown(true);
//     console.log('Exit intent: page', exitIntentShown);
//   },
// });

export const PagePayContext = createContext<any>(null);

export interface PagePayProps {
  className?: string;
  inModal?: boolean;
  onPayment?: any;
  customHeading?: string;
}

const PagePay: FC<PagePayProps> = ({
  className = '',
  inModal,
  onPayment,
  customHeading,
}) => {
  const user = useAuthUser()();
  const userId = user?.id;
  const authHeader = useAuthHeader()();

  const [showExitIntentDialog, setShowExitIntentDialog] = useState(false);

  const [exitIntentShown, setExitIntentShown] = useState(false);

  const [mouseLeaveTimeoutId, setMouseLeaveTimeoutId] = useState<any>();

  const [showSignupModalSequence, setShowSignupModalSequence] = useState(false);

  useEffect(() => {
    axios
      .get(`${backendURL}/survey/buy-credits/dialogShown`, {
        headers: {
          Authorization: authHeader,
        },
      })
      .then(({ data: dialogShown }) => {
        setExitIntentShown(dialogShown);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (showExitIntentDialog) {
      axios
        .get(`${backendURL}/survey/buy-credits/markDialogAsShown`, {
          headers: {
            Authorization: authHeader,
          },
        })
        .then(() => {
          // ReactGA.event('survey_test', {
          //   shownToUser: false,
          // });
          // ReactPixel.track('survey_test', {
          //   shownToUser: false,
          // });
        })
        .catch(() => {});
    }
  }, [showExitIntentDialog]);
  // mouse leaves, wait 1 second show dialog. Clear timeout on mouse enter and set id in state var to undefined

  // const [pageSessionId, setPageSessionId] = useState<string | undefined>();

  // useEffect(() => {
  //   ReactGA.event('buy_credits_page_view');
  //   axios
  //     .post(
  //       `${backendURL}/pageSessions/buy-credits`,
  //       { browser: getBrowser(), getOperatingSystem: getOperatingSystem() },
  //       { headers: { Authorization: authHeader } }
  //     )
  //     .then(({ data }) => setPageSessionId(data))
  //     .catch(() => console.log('Error creating page session'));
  // }, []);

  // const updatePageSession = (body: any) =>
  //   axios
  //     .patch(
  //       `${backendURL}/pageSessions/buy-credits/${pageSessionId}`,
  //       body,
  //       { headers: { Authorization: authHeader } }
  //     )
  //     .catch(() => console.log('Error updating page session'));

  // const [amountOfCredits, setAmountOfCredits] = useState<any>(undefined);
  // const [customInputAmount, setCustomInputAmount] = useState<any>(undefined);
  const [billingFrequency, setBillingFrequency] = useState<string>('one-time');

  const [currency, setCurrency] = useState<any>(
    languageToCurrency?.[navigator?.language] ?? 'USD'
  );

  const [exchangeRates, setExchangeRates] = useState<
    Record<string, number> | undefined
  >(undefined);
  const [fetchingExchangeRates, setFetchingExchangeRates] =
    useState<boolean>(true);
  const [creditSubscriptionsInfo, setCreditSubscriptionsInfo] = useState<
    string[] | undefined
  >(undefined);
  const [fetchingCreditSubscriptionsInfo, setFetchingCreditSubscriptionsInfo] =
    useState<boolean>(true);
  const [paypalButtonsStatusMessage, setPaypalButtonsStatusMessage] =
    useState<string>('');
  const [
    creditSubscriptionsStatusMessage,
    setCreditSubscriptionsStatusMessage,
  ] = useState<string>('');

  const fetchAndSetExchangeRates = async () => {
    try {
      const response = await fetch(`${backendURL}/fetch-exchange-rates`);
      const responseJson = await response.json();
      setExchangeRates(responseJson);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingExchangeRates(false);
    }
  };

  useEffect(() => {}, [exchangeRates]);

  // const currencySelectRef = useRef();

  const fetchAndSetCreditSubscriptionsInfo = async () => {
    if (!userId) return;
    
    try {
      const response = await axios.get(
        `${backendURL}/paypal/subscriptions/${userId}`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );

      // if (response.status === 204) {
      //   response = await axios.get(`${backendURL}/stripe/subscription`, {
      //     headers: {
      //       Authorization: authHeader,
      //     },
      //   });
      // }

      const responseData = response?.data;

      if (responseData) {
        const {
          amountOfCredits,
          dateOfNextPayment,
          currencyCode,
          amountToPay,
          frequency,
        } = responseData;

        setCreditSubscriptionsInfo([
          `${amountOfCredits} credits ${
            frequency === 'monthly'
              ? `(${formatCurrencyRoundedUp(+amountToPay, currencyCode)}) `
              : ''
          }per month, next payment due at ${new Date(
            dateOfNextPayment
          ).toLocaleDateString()}`,
        ]);
      }
    } catch (error) {
      console.log(error);

      return setCreditSubscriptionsStatusMessage(
        'There was an error fetching your current credit subscription (if you have one)'
      );
    } finally {
      setFetchingCreditSubscriptionsInfo(false);
    }
  };

  const [abTestVariant, setAbTestVariant] = useState<undefined | number>(
    undefined
  );

  const [paymentOptionsData, setPaymentOptionsData] = useState<
    undefined | any[]
  >();

  const [selectedAmountOfCredits, setSelectedAmountOfCredits] =
    useState<number>();

  useEffect(() => {
    axios
      .get(`${backendURL}/site/paymentOptionsData`)
      .then(({ data }) => {
        setPaymentOptionsData(data);
        /* on the local site there is a bug where if you click one-time after
           the page loads and select an amount, the paypal buttons don't load,
           doesn't seem to be a problem on the live site though. Setting a 
           default selected amount of credits like below fixed this */
        // setSelectedAmountOfCredits(data[0].credits);
      })
      .catch(error => console.log('Error loading payment options:', error));
  }, []);

  const findAndSetAbTestVariant = async () => {
    try {
      const response: any = await axios.get(
        `${backendURL}/user/get-test-variant/buy-credits`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      const { testVariant } = response.data;
      if (!testVariant) throw new Error('No test variant returned');

      setAbTestVariant(testVariant);

      ReactGA.event('test_variant_loaded', {
        page: 'buy-credits',
        testVariant,
      });
    } catch (error) {
      setAbTestVariant(1);
    }
  };

  useEffect(() => {
    findAndSetAbTestVariant();
    fetchAndSetExchangeRates();
  }, []);

  useEffect(() => {
    if (fetchingCreditSubscriptionsInfo) fetchAndSetCreditSubscriptionsInfo();
  }, [fetchingCreditSubscriptionsInfo]);

  const exchangeRateFromDollarsToSelectedCurrency = exchangeRates
    ? exchangeRates[currency]
    : unconvertedCurrencies.includes(currency)
      ? approximateExchangeRateFromDollarsTo[currency]
      : 1;

  // const customCreditsInSelectedCurrency = formatCurrencyRoundedUp(
  //   creditsToCurrency({
  //     credits: +customInputAmount,
  //     exchangeRateFromDollars: exchangeRateFromDollarsToSelectedCurrency,
  //   }),
  //   currency
  // );

  const hasMultipleSubscriptions =
    creditSubscriptionsInfo && creditSubscriptionsInfo.length > 1;

  const cancelSubscriptions = async () => {
    setPaypalButtonsStatusMessage('');
    setCreditSubscriptionsStatusMessage(
      `Cancelling subscription${hasMultipleSubscriptions ? 's' : ''}...`
    );
    try {
      const responses: any = await Promise.allSettled([
        axios.delete(`${backendURL}/paypal/subscriptions/${userId}`, {
          headers: {
            Authorization: authHeader,
          },
        }),
        axios.delete(`${backendURL}/stripe/subscription`, {
          headers: {
            Authorization: authHeader,
          },
        }),
      ]);

      if (!responses.some(({ status }: any) => status === 'fulfilled')) {
        throw new Error();
      }

      setCreditSubscriptionsInfo([]);
      setCreditSubscriptionsStatusMessage(
        'Subscription successfully cancelled!'
      );
    } catch (error) {
      console.log(error);
      setCreditSubscriptionsStatusMessage(
        'There was an error cancelling your subscription.'
      );
    }
  };

  // const [paypalSectionShowing, setPaypalSectionShowing] = useState(true);

  const paymentSelectionScrollPointRef = useRef<any>();

  const creditAmountHeadingClass = (amountOfCredits: number) =>
    `flex flex-col items-center justify-center text-center p-2 pl-0 font-${
      selectedAmountOfCredits === amountOfCredits ? 'black' : 'semibold'
    }`;

  const billingFrequencyHeadingClass = (frequency: string) =>
    `flex items-center justify-center text-center p-2 font-${billingFrequency === frequency ? 'black' : 'semibold'}`;

  const tableCellClass = (amountOfCredits: number, frequency: string) =>
    `rounded-md cursor-pointer flex flex-col items-center justify-center p-2 font-semibold shadow-lg border-2 hover:shadow-blue  ${ //border-black  bg-purple-100 
      selectedAmountOfCredits === amountOfCredits &&
      billingFrequency === frequency
        ? 'bg-blue-100 text-white' // bg-blue-700
        : 'bg-white text-black'
    }`;

  const [paypalButtonsLoadRetries, setPaypalButtonsLoadRetries] =
    useState<number>(10);

  const navigate = useNavigate();

  if (user?.trialAccount) {
    navigate('/signup');
  }

  return (
    <PagePayContext.Provider
      value={{
        fetchingCreditSubscriptionsInfo,
        setFetchingCreditSubscriptionsInfo,
        paypalButtonsStatusMessage,
        setPaypalButtonsStatusMessage,
        creditSubscriptionsStatusMessage,
        setCreditSubscriptionsStatusMessage,
        billingFrequency,
        SANDBOX_MODE,
        inModal,
        onPayment,
        userId,
        selectedAmountOfCredits,
        setSelectedAmountOfCredits,
        setBillingFrequency,
        paymentSelectionScrollPointRef,
        exchangeRates,
        paypalButtonsLoadRetries,
        setPaypalButtonsLoadRetries,
        setShowSignupModalSequence
      }}
    >
      <div
        className={`nc-PageOrder ${className}`}
        data-nc-id='PageOrder'
        onMouseLeave={event => {
          const { top: containerTop } = (
            event.target as any
          ).getBoundingClientRect();

          const { clientY } = event;

          if (exitIntentShown) {
            return;
          }

          if (clientY <= containerTop || (containerTop < 0 && clientY < 30)) {
            const timeoutId = setTimeout(() => {
              setShowExitIntentDialog(true);
              setExitIntentShown(true);
            }, 1000);

            setMouseLeaveTimeoutId(timeoutId);
          }
        }}
        onMouseEnter={() => {
          if (!mouseLeaveTimeoutId) {
            return;
          }
          clearTimeout(mouseLeaveTimeoutId);
          setMouseLeaveTimeoutId(undefined);
        }}
      >
        {showExitIntentDialog && <BuyCreditsExitIntentDialog />}

        <Helmet>
          <title>Get More Credits - Skillfusion</title>
        </Helmet>
        {SANDBOX_MODE && <h1 className='text-xl text-center'>SANDBOX MODE</h1>}

        <main
          className={`container ${inModal ? '' : 'mt-11'} flex flex-col gap-2`}
        >
          {customHeading && (
            <h1 className='text-xl text-center'>{customHeading}</h1>
          )}
          {fetchingCreditSubscriptionsInfo ? (
            <></> /*<p>Searching for any existing credit subscriptions...</p>*/
          ) : creditSubscriptionsInfo && creditSubscriptionsInfo.length > 0 ? (
            <>
              <h1 className='text-xl'>
                Your current credit subscription
                {hasMultipleSubscriptions && 's'}
              </h1>
              <ul>
                {creditSubscriptionsInfo.map((info, infoIndex) => (
                  <li key={infoIndex}>{info}</li>
                ))}
              </ul>
              <button
                onClick={cancelSubscriptions}
                className='border border-1 border-black rounded-lg p-1 px-2 w-max'
              >
                Cancel subscription{hasMultipleSubscriptions ? 's' : ''}
              </button>
            </>
          ) : null}

          {creditSubscriptionsStatusMessage ? (
            <>
              <p>{creditSubscriptionsStatusMessage}</p>
              <br />
            </>
          ) : (
            <></>
          )}

          <h1 className='text-xl rounded-lg text-blue-400'>
            {/*Fuel Your Success*/} Unlock AI - With Skillfusion</h1>

          <p>
            Write books, generate logos, write
            marketing content, get business advice, and much more.
            <br />
            185 powerful tools, and more being added every week.{' '}
          </p>

          <br />
{/*
          <h1 className='text-xl rounded-lg text-blue-400'>
            How much can you get for your credits?{' '}
          </h1>
          <p>
            - A 10 chapter fiction book with around 30,000 words costs
            approximately 25 credits
            <br />
            - SEO articles to help rank highly on Google, 3000 words each
            with 4 unique images costs 12 credits
            <br />
            - 40 logos ideas made with Dall-e 3 costs approximately 30
            credits
            <br />
            - 5 audio and text summaries of non-fiction books (5 x 25
            minutes of audio) for 70 credits
            <br />
            {
              "- Instructions for 10 custom GPTs for OpenAI's GPT store costs approximately 70 credits"
            }
          </p>

          <br />
          */}
          
          <h2 className='text-xl rounded-lg text-blue-400'>
           {/* Step 1 - Choose the number of credits you would like, as
            &ldquo;One-Time&ldquo; or &ldquo;Monthly&ldquo;:*/}
            Get More Credits
          </h2>

          {/* <Switch
            checked={selectedPaymentFrequency === 'one time'}
            onChange={() => {
              setSelectedPaymentFrequency(previousPaymentFrequency =>
                previousPaymentFrequency === 'monthly' ? 'one time' : 'monthly'
              );
            }}
            className='group inline-flex p-x-2 h-12 w-52 items-center rounded-full bg-blue-200 transition'
          >
            <span className='h-10 w-25 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6'>
              Monthly
            </span>
            <span className='h-10 w-25 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6'>
              One time
            </span>
          </Switch> */}

          {/* <div className='flex justify-between gap-2 p-2 inline w-fit rounded-full bg-blue-400 inline'>
            <button
              onClick={() => {
                setBillingFrequency('yearly');
                setPaypalButtonsStatusMessage('');
                ReactGA.event('yearly_payment_options_selected');
              }}
              className={`rounded-full px-2 py-1 font-semibold ${
                billingFrequency === 'yearly'
                  ? 'bg-white text-blue-400'
                  : 'text-white'
              }`}
            >
              Yearly
            </button>
            <button
              onClick={() => {
                setBillingFrequency('monthly');
                setPaypalButtonsStatusMessage('');
                ReactGA.event('monthly_payment_options_selected');
              }}
              className={`rounded-full px-2 py-1 font-semibold ${
                billingFrequency === 'monthly'
                  ? 'bg-white text-blue-400'
                  : 'text-white'
              }`}
            >
              Monthly
            </button>
            <button
              onClick={() => {
                setBillingFrequency('one-time');
                setPaypalButtonsStatusMessage('');
                ReactGA.event('one-time_payment_options_selected');
              }}
              className={`rounded-full px-2 py-1 font-semibold ${
                billingFrequency === 'one-time'
                  ? 'bg-white text-blue-400'
                  : 'text-white'
              }`}
            >
              One time
            </button>
          </div> */}


{paymentOptionsData && (
  <div className='grid grid-cols-[auto_1fr_1fr] gap-2 w-fit'>
    <div />
    <div className={billingFrequencyHeadingClass('monthly')}>
      Monthly subscription
    </div>
    <div className={billingFrequencyHeadingClass('one-time')}>
      One-time purchase
    </div>
    <>
      {paymentOptionsData.map(
        ({
          credits,
          bonusMonthlyCredits,
          oneTimePriceInDollars,
          monthlyPriceInDollars,
          monthlyPriceForYearlySubscriptionInDollars,
        }) => (
          <React.Fragment key={credits}>
            <div className={creditAmountHeadingClass(credits)}>
              {`${credits} Credits`}
              {['monthly', 'yearly'].includes(billingFrequency) && (
                <p className='text-xs'>per month</p>
              )}
            </div>
            <div
              className={
                tableCellClass(credits, 'monthly') + ' gap-2'
              }
              onClick={() => {
                if (!userId) return setShowSignupModalSequence(true);

                setSelectedAmountOfCredits(credits);
                setBillingFrequency('monthly');
                if (!paymentSelectionScrollPointRef.current) return;
                setTimeout(() => {
                  paymentSelectionScrollPointRef.current.scrollIntoView(
                    {
                      block: 'nearest',
                      behavior: 'smooth',
                    }
                  );
                }, 200);
              }}
            >
              <h1 className='text-lg text-center underline text-blue-700'>
                {formatCurrencyRoundedUp(
                  exchangeRateFromDollarsToSelectedCurrency *
                    monthlyPriceInDollars,
                  currency
                )}
                <p className='text-xs text-center'>
                  {!bonusMonthlyCredits ? null : (
                    <span className='text-xs text-center'>
                      {bonusMonthlyCredits} Bonus Credits for the first
                      month
                    </span>
                  )}
                </p>
              </h1>
            </div>
            <div
              className={tableCellClass(credits, 'one-time')}
              onClick={() => {
                if (!userId) return setShowSignupModalSequence(true);

                setSelectedAmountOfCredits(credits);
                setBillingFrequency('one-time');
                if (!paymentSelectionScrollPointRef.current) return;
                setTimeout(() => {
                  paymentSelectionScrollPointRef.current.scrollIntoView(
                    {
                      block: 'nearest',
                      behavior: 'smooth',
                    }
                  );
                }, 200);
              }}
            >
              <h1 className='text-lg underline text-blue-700'>
                {formatCurrencyRoundedUp(
                  exchangeRateFromDollarsToSelectedCurrency *
                    oneTimePriceInDollars,
                  currency
                )}
              </h1>
            </div>
            {/* <div
              className={tableCellClass(credits, 'yearly')}
              onClick={() => {
                setSelectedAmountOfCredits(credits);
                setBillingFrequency('yearly');
              }}
            >
              <h1 className=''>
                {formatCurrencyRoundedUp(
                  exchangeRateFromDollarsToSelectedCurrency *
                    monthlyPriceForYearlySubscriptionInDollars,
                  currency
                )}
              </h1>
              <p className='text-xs text-center'>
                per month - paid yearly
              </p>
            </div> */}
          </React.Fragment>
        )
      )}
    </>

    {paypalButtonsStatusMessage && <p>{paypalButtonsStatusMessage}</p>}

          <div className="col-span-3">
            {selectedAmountOfCredits && (
              <PayPalSection
                currency={
                  unconvertedCurrencies.includes(currency) ? currency : 'USD'
                }
                amountOfCredits={selectedAmountOfCredits}
              />
            )}
          </div>
          <hr />

  </div>
)}

    
          {/* {paymentOptionsData ? (
            <div className='flex flex-col items-start gap-4 md:grid md:grid-cols-2 xl:flex xl:flex-row'>
              {paymentOptionsData.map(
                ({
                  credits,
                  oneTimePriceInDollars,
                  bonusMonthlyCredits,
                  monthlyPriceInDollars,
                  monthlyPriceForYearlySubscriptionInDollars,
                }) => (
                  <PaymentOptionCard
                    key={credits}
                    numberOfCredits={credits}
                    currency={currency}
                    oneTimePriceInChosenCurrency={
                      exchangeRateFromDollarsToSelectedCurrency *
                      oneTimePriceInDollars
                    }
                    monthlyPriceInChosenCurrency={
                      exchangeRateFromDollarsToSelectedCurrency *
                      monthlyPriceInDollars
                    }
                    bonusMessage={`${bonusMonthlyCredits} Bonus Credits for the first month`}
                  />
                )
              )}
            </div>
          ) : (
            <p>Loading payment options...</p>
          )} */}

          
          <br />

          {/*<h2 className='text-xl rounded-lg text-blue-400'>
            Step 2 - Payment Options will appear here:
          </h2>*/}
          
          <div className='flex flex-wrap gap-4'>
            <div className='flex flex-col'>
              {/*}
              <br />
              <img
                src={credentials}
                className='w-[400px] pt-4'
              />*/}
              <br />
              <p>
                All payments are handled by paypal. Skillfusion do not get
                access to or store your payment details.
              </p>
              <br />
              <p ref={paymentSelectionScrollPointRef}>
                Note: come back to this page at any time to easily cancel your
                monthly subscription.
              </p>

              <br />


              <br />
          <label>Change currency if needed:</label>
          {fetchingExchangeRates ? (
            <p>Loading exchange rates...</p>
          ) : exchangeRates ? (
            <Select
              styles={{
                container: (baseStyles, state) => ({
                  ...baseStyles,
                  width: 'auto',
                }),
              }}
              onChange={({ value }) => {
                setPaypalButtonsStatusMessage('');
                setCurrency(value);
              }}
              options={supportedCurrencyCodeNamePairs.map(([code, name]) => ({
                value: code,
                label: `${name} (${code})`,
              }))}
              formatOptionLabel={({ value: currencyCode, label }) => (
                <div className='flex gap-2'>
                  <span
                    className={`fi fi-${currencyCode
                      .slice(0, 2)
                      .toLowerCase()}`}
                  ></span>
                  {label}
                </div>
              )}
              defaultValue={currency}
            />
          ) : (
            <p>
              There was an error loading exchange rates. Try reloading the page.
            </p>
          )} <br /> <br />

              {/*
              <h1 className='text-xl rounded-lg text-blue-400 text-center'>
                What can you get with your credits?{' '}
              </h1>

  <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16 pt-0 sm:pt-0 md:pt-2">
    <div className="grid grid-cols-1 md:grid-cols-2 sm:grid-cols-1 gap-10">

<div className="flex items-center">
<img className="rounded-full w-1/4"
            src={payFiction}
            alt="Sunset in the mountains" /> 
            <div className="pl-4 w-full md:w-1/2">
          <p className='w-full'><b>30,000 Words of fiction</b></p>
<p>10 chapters</p>
<p className="text-right">. . . . . 25 Credits</p></div>  
</div>


<div className="flex items-center">
<img className="rounded-full w-1/4"
            src={paySEO}
            alt="Sunset in the mountains" /> 
            <div className="pl-4 w-full md:w-1/2">
          <p className='w-full'><b>High quality SEO Article + 4 images</b></p>
<p>rank higher on Google</p>
<p className="text-right">. . . . . 12 Credits</p></div>  
</div>


    <div className="rounded-full overflow-hidden shadow-lg">
<div className="relative">
    <span>
        <img className="w-full"
            src={paySEO}
            alt="Sunset in the mountains" />
        <div
            className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25">
        </div>
    </span>



</div>
<p><b>30,000 Words of fiction</b></p>
<p>10 chapters</p>
<p>25 Credits</p>
</div>





    <div className="rounded overflow-hidden shadow-lg">
<div className="relative">
    <span>
        <img className="w-full"
            src={payFiction}
            alt="Sunset in the mountains" />
        <div
            className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25">
        </div>
    </span>
    <span>
        <div
            className="absolute top-0 w-full text-center left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
            30,000 words of fiction
        </div>
    </span>


</div>
<div className="px-6 py-4 bg-purple-600">
    <p className="text-4xl text-white text-center">
        25 credits
    </p>
</div>
</div>



<div className="rounded overflow-hidden shadow-lg">
<div className="relative">
    <span>
        <img className="w-full"
            src={paySEO}
            alt="Sunset in the mountains" />
        <div
            className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25">
        </div>
    </span>
    <span>
        <div
            className="absolute top-0 w-full text-center left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
            High quality SEO Article + 4 images
        </div>
    </span>


</div>
<div className="px-6 py-4 bg-purple-600">
    <p className="text-4xl text-white text-center">
        12 credits
    </p>
</div>
</div>


<div className="rounded overflow-hidden shadow-lg">
<div className="relative">
    <span>
        <img className="w-full"
            src={payLogos}
            alt="Sunset in the mountains" />
        <div
            className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25">
        </div>
    </span>
    <span>
        <div
            className="absolute top-0 w-full text-center left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
            40 logo designs to choose from
        </div>
    </span>


</div>
<div className="px-6 py-4 bg-purple-600">
    <p className="text-4xl text-white text-center">
        30 credits
    </p>
</div>
</div>

<div className="rounded overflow-hidden shadow-lg">
<div className="relative">
    <span>
        <img className="w-full"
            src={paySEO}
            alt="Sunset in the mountains" />
        <div
            className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25">
        </div>
    </span>
    <span>
        <div
            className="absolute bottom-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
            SEO Article + Images
        </div>
    </span>

    <span>
        <div
            className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
            <span className="font-bold">12</span>
            <small>Credits</small>
        </div>
    </span>
</div>
<div className="px-6 py-4">
    <p className="text-gray-500 text-sm">
      High quality SEO article with images to help your Google rankings
    </p>
</div>
</div>


<div className="rounded overflow-hidden shadow-lg">
<div className="relative">
    <span>
        <img className="w-full"
            src={payLogos}
            alt="Sunset in the mountains" />
        <div
            className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25">
        </div>
    </span>
    <span>
        <div
            className="absolute bottom-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
            40 Logos
        </div>
    </span>

    <span>
        <div
            className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
            <span className="font-bold">30</span>
            <small>Credits</small>
        </div>
    </span>
</div>
<div className="px-6 py-4">
    <p className="text-gray-500 text-sm">
        40 logo designs to choose from
    </p>
</div>
</div>



        <div className="rounded overflow-hidden shadow-lg">

            <a href="#"></a>
            <div className="relative">
                <a href="#">
                    <img className="w-full"
                        src="https://images.pexels.com/photos/196667/pexels-photo-196667.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=1&amp;w=500"
                        alt="Sunset in the mountains" />
                    <div
                        className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25">
                    </div>
                </a>
                <a href="#!">
                    <div
                        className="absolute bottom-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                        Photos
                    </div>
                </a>

                <a href="!#">
                    <div
                        className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                        <span className="font-bold">27</span>
                        <small>March</small>
                    </div>
                </a>
            </div>
            <div className="px-6 py-4">

                <a href="#"
                    className="font-semibold text-lg inline-block hover:text-indigo-600 transition duration-500 ease-in-out">Best
                    View in Newyork City</a>
                <p className="text-gray-500 text-sm">
                    The city that never sleeps
                </p>
            </div>
            <div className="px-6 py-4 flex flex-row items-center">
                <span className="py-1 text-sm font-regular text-gray-900 mr-1 flex flex-row items-center">
                    <svg height="13px" width="13px" version="1.1" id="Layer_1" 
                         x="0px" y="0px" viewBox="0 0 512 512"
                         enableBackground="new 0 0 512 512" >
                        <g>
                            <g>
                                <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M277.333,256
			c0,11.797-9.536,21.333-21.333,21.333h-85.333c-11.797,0-21.333-9.536-21.333-21.333s9.536-21.333,21.333-21.333h64v-128
			c0-11.797,9.536-21.333,21.333-21.333s21.333,9.536,21.333,21.333V256z"></path>
                            </g>
                        </g>
                    </svg>
                    <span className="ml-1">6 mins ago</span></span>
            </div>
        </div>
        <div className="rounded overflow-hidden shadow-lg">
            <a href="#"></a>
            <div className="relative">
                <a href="#">
                    <img className="w-full"
                        src="https://images.pexels.com/photos/1653877/pexels-photo-1653877.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=1&amp;w=500"
                        alt="Sunset in the mountains" />
                    <div
                        className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25">
                    </div>
                </a>
                <a href="#!">
                    <div
                        className="absolute bottom-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                        Photos
                    </div>
                </a>

                <a href="!#">
                    <div
                        className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                        <span className="font-bold">20</span>
                        <small>March</small>
                    </div>
                </a>
            </div>
            <div className="px-6 py-4">

                <a href="#"
                    className="font-semibold text-lg inline-block hover:text-indigo-600 transition duration-500 ease-in-out">Best
                    Pizza in Town</a>
                <p className="text-gray-500 text-sm">
                    The collection of best pizza images in Newyork city
                </p>
            </div>
            <div className="px-6 py-4 flex flex-row items-center">
                <span
                    className="py-1 text-sm font-regular text-gray-900 mr-1 flex flex-row justify-between items-center">

                      
                    <svg height="13px" width="13px" version="1.1" id="Layer_1" 
                         x="0px" y="0px" viewBox="0 0 512 512"
                         enableBackground="new 0 0 512 512" 
                         >
                        <g>
                            <g>
                                <path
                                    d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M277.333,256 c0,11.797-9.536,21.333-21.333,21.333h-85.333c-11.797,0-21.333-9.536-21.333-21.333s9.536-21.333,21.333-21.333h64v-128 c0-11.797,9.536-21.333,21.333-21.333s21.333,9.536,21.333,21.333V256z">
                                </path>
                            </g>
                        </g>
                    </svg>
                    <span className="ml-1">3 mins read</span></span>
            </div>
        </div>
        <div className="rounded overflow-hidden shadow-lg">

            <a href="#"></a>
            <div className="relative">
                <a href="#">
                    <img className="w-full"
                        src="https://images.pexels.com/photos/257816/pexels-photo-257816.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=1&amp;w=500"
                        alt="Sunset in the mountains" />
                    <div
                        className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25">
                    </div>
                </a>
                <a href="#!">
                    <div
                        className="absolute bottom-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                        Photos
                    </div>
                </a>

                <a href="!#">
                    <div
                        className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                        <span className="font-bold">15</span>
                        <small>April</small>
                    </div>
                </a>
            </div>
            <div className="px-6 py-4">

                <a href="#"
                    className="font-semibold text-lg inline-block hover:text-indigo-600 transition duration-500 ease-in-out">Best
                    Salad Images ever</a>
                <p className="text-gray-500 text-sm">
                    The collection of best salads of town in pictures
                </p>
            </div>
            <div className="px-6 py-4 flex flex-row items-center">
                <span
                    className="py-1 text-sm font-regular text-gray-900 mr-1 flex flex-row justify-between items-center">
                    <svg height="13px" width="13px" version="1.1" id="Layer_1" 
                        x="0px" y="0px" viewBox="0 0 512 512"
                        enableBackground="new 0 0 512 512" >
                        <g>
                            <g>
                                <path
                                    d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M277.333,256 c0,11.797-9.536,21.333-21.333,21.333h-85.333c-11.797,0-21.333-9.536-21.333-21.333s9.536-21.333,21.333-21.333h64v-128 c0-11.797,9.536-21.333,21.333-21.333s21.333,9.536,21.333,21.333V256z">
                                </path>
                            </g>
                        </g>
                    </svg>
                    <span className="ml-1">6 mins read</span></span>
            </div>
        </div>
              
    </div>
</div>
*/}

              <h1 className='text-xl rounded-lg text-blue-400'>
                How Do Credits Work?
              </h1>

              <p>
                Credits are used when a tool does anything that Skillfusion has
                to pay for:
                <br />
                - Prompts to the Chatgpt API and other AIs
                <br />
                - AI image generation
                <br />
                - Embedding and searching text (Ada API)
                <br />- Google Search API
              </p>
              <br />
              <p>
                You can also use credits to buy paid tools. Skillfusion pays
                100% commission to the user that made the tool.
              </p>
              <br />

              <p>
                Your credits have no expiration. Monthly credits will
                accumulate.
              </p>
              {/*}
              <p>
                All payments are handled by paypal. Skillfusion does not get
                access to, and does not store, your payment details.
              </p>
*/}

              <br />
              <br />
              {/*<span>
                <img
                  src={guarantee}
                  className='w-[200px] float-left pr-4'
                />
                <h1 className='text-xl rounded-lg text-blue-400'>
                  Money Back Guarantee
                </h1>
                <p>
                  {
                    "If it is your first month and you aren't happy for any reason, message us for a complete refund (admin@skillfusion.ai)."
                  }
                </p>
                </span>*/}

              {/*
              <img
                src={credentials}
                className='w-[400px] pt-4'
              />
              <br />
            
              <br />*/}
            </div>
          </div>
          <br />

          <h1 className='text-xl rounded-lg text-blue-400'>What Users Say</h1>

          {/* <div className='lg:w-1/2'>
            <Carousel
              autoPlay
              infiniteLoop
              interval={5000}
              useKeyboardArrows
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              dynamicHeight={true}
            >
              {Array.from({ length: 15 }, (_, i) => i + 1).map(i => (
                <div
                  key={i}
                  className=''
                >
                  <img
                    src={`https://blog.skillfusion.ai/wp-content/uploads/${i}.jpg`}
                    className='lg:w-1/2'
                  />
                  { <div className='legend'>
                              <p className='leading-normal'></p>
                            </div> }
                </div>
              ))}
            </Carousel>
          </div> */}

          <img
            src='https://blog.skillfusion.ai/wp-content/uploads/1.jpg'
            className='lg:w-1/2'
          />
          <img
            src='https://blog.skillfusion.ai/wp-content/uploads/2.jpg'
            className='lg:w-1/2'
          />
          <img
            src='https://blog.skillfusion.ai/wp-content/uploads/3.jpg'
            className='lg:w-1/2'
          />
          <img
            src='https://blog.skillfusion.ai/wp-content/uploads/4.jpg'
            className='lg:w-1/2'
          />
          <img
            src='https://blog.skillfusion.ai/wp-content/uploads/5.jpg'
            className='lg:w-1/2'
          />
          <img
            src='https://blog.skillfusion.ai/wp-content/uploads/6.jpg'
            className='lg:w-1/2'
          />
          <img
            src='https://blog.skillfusion.ai/wp-content/uploads/7.jpg'
            className='lg:w-1/2'
          />
          <img
            src='https://blog.skillfusion.ai/wp-content/uploads/8.jpg'
            className='lg:w-1/2'
          />
          <img
            src='https://blog.skillfusion.ai/wp-content/uploads/9.jpg'
            className='lg:w-1/2'
          />
          <img
            src='https://blog.skillfusion.ai/wp-content/uploads/10.jpg'
            className='lg:w-1/2'
          />
          <img
            src='https://blog.skillfusion.ai/wp-content/uploads/11.jpg'
            className='lg:w-1/2'
          />
          <img
            src='https://blog.skillfusion.ai/wp-content/uploads/12.jpg'
            className='lg:w-1/2'
          />
          <img
            src='https://blog.skillfusion.ai/wp-content/uploads/13.jpg'
            className='lg:w-1/2'
          />
          <img
            src='https://blog.skillfusion.ai/wp-content/uploads/14.jpg'
            className='lg:w-1/2'
          />
          <img
            src='https://blog.skillfusion.ai/wp-content/uploads/15.jpg'
            className='lg:w-1/2'
          />
        </main>
        {showSignupModalSequence && (
          <ModalContainer onClose={() => {
            setShowSignupModalSequence(false);
          }}>
            <div
              className="flex p-8 justify-between gap-4 overflow-y-auto max-w-full max-h-[87vh]"
            >
              <SignupModalSequence />
            </div>
            <div className="flex gap-2 items-center"></div>
          </ModalContainer>
        )}
      </div>
    </PagePayContext.Provider>
  );
};

export default PagePay;
