import { useEffect, useContext } from "react";
import FuserContext from "../context/FuserContext";
import { blockTypesExpandedByDefault, blocktypesThatDontRunAutomatically } from "../constants/blocks";

const useBlockRunner = (runFunction: () => any, blockIndex: number) => {
  const {
    runnerIndex,
    runnerMode,
    stillRunning,
    setBlocks,
    blocks,
    setCollapsedBlocks,
  } = useContext(FuserContext);

  const block = blocks?.[blockIndex];
  useEffect(() => {
    // shared blocks will have no blocks array
    if (!block) return;

    (async () => {
      if (runnerMode && stillRunning && blockIndex === runnerIndex) {
        const blockId = block.id;
        const collapseAfterRunning =
          block.collapseAfterRunning !== undefined
            ? block.collapseAfterRunning
            : blockTypesExpandedByDefault.includes(block.type)
              ? false
              : true;

        // expand current block
        setCollapsedBlocks(
          (currentCollapsedBlocks: any) =>
            currentCollapsedBlocks.filter((id: string) => id !== blockId)
          // the code commented below collapses all other blocks also
          // blocks
          //   .filter(
          //     ({ type }: any, index: number) =>
          //       !(
          //         (type === 'blockdiv-download' && index < blockIndex) ||
          //         index === blockIndex
          //       )
          //   )
          //   .map(({ id }: any) => id)
        );

        if (blocktypesThatDontRunAutomatically.includes(block.type)) return;

        const blockUpdater = await runFunction();

        if (blockUpdater) setBlocks(blockUpdater);

        setCollapsedBlocks((currentCollapsedBlocks: any) => [
          ...(collapseAfterRunning ? [blockId] : []),
          ...currentCollapsedBlocks,
        ]);

        console.log(`block ${blockIndex} finished running`);
        // if (runResult === 'CANCEL') return setRunnerIndex(-Infinity);

        // refresh correct index
        // runnerIndex.current=blockIndex;
        // add previous too if I get bugs
        // blockRunning = false;
      }
    })();
  }, [stillRunning, runnerMode, runnerIndex]);
};

export default useBlockRunner;
